@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.right__main {
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  &>div.right__main__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  };

  .bottom {
    margin-top: auto;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 24px;
  }
}