@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.input-radio {
  min-width: 0;
  max-width: auto;
  &>label {
    @extend .medium-type;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: left;
    cursor: pointer;
    
    .radio {
      background-color: #FFFFFF;
      border: 1px solid #E6E6E6;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 8px;
      display:flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
    &>input[type="radio"] {
      opacity: 0;
      width:0;
    }
    
    &>input[type="radio"]:checked + .radio {
      &:before {
        content: '';
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: $color_azure;
      }
      img {
        visibility: visible;
      }
    }
  }
}