@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/typography';

.accordian {
  background: #FFFFFF;
  border: 1px solid $color_grey_light2;
  box-sizing: border-box;
  border-radius: 8px;

  .heading {
    font-family: $fontFamilyBold;
    color: $color_nightsky;
    border-bottom: 1px solid $color_grey_light2;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.2em 1.8em;
    cursor: pointer;

    img {
      flex: 0 0 10px;
      margin-left: 1.5em;
      transform-origin: center center;
      transform: rotate(180deg);
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      &.invert {
        transform: rotate(0deg);
      }
    }
  }

  .content {
    @extend .txt-md;
    height:0;
    overflow: hidden;
    transition-property: all;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;

    .inside {
      padding: 1.2em 1.8em;
    }

    &.expanded {
      height:auto;
    }
  }
}

@media screen and (max-width: 1023px) {
  .accordian {
    .heading {
      font-family: $fontFamilyBold;
      font-size: 14px;
    }
    .content {
      .inside {
        padding: 1.6em 1.6em;
      }  
    }
  } 
}