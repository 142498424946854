.startup-match-results {
  .match-tech-requests-inner {
    margin-left:-16px;
    margin-right:-16px;
  }
  .card-wrapper.match-tech-request-card {
    margin-right:16px;
    margin-left:16px;
    margin-bottom:32px;
    width: 370px;
    height:360px;
    .card.vertical {
      .card-image {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
      }
      .card-body {
        .card-body-content {
          h5 {
          }
          p {
            margin-bottom: 16px;
            max-height: 40px;
          }
        }
        .card-footer {
          padding:0;
          .tech-solution-match-result-card-footer {
            display: flex;
            flex-direction: row;
            position: relative;
            flex: 0 0 100%;
            border-bottom-right-radius: 8px;
            overflow: hidden;
            
            .info {
              visibility: visible;
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
              padding-left: 20px;
              padding-right: 20px;
            }
            .action {
              pointer-events: none;
              visibility: hidden;
              position: absolute;
              left: 0;
              border-radius: 0;
              width: 100%;
              padding:10px 20px;
            }
  
            &:hover {
              .info {
                visibility: hidden;
              }
              .action {
                pointer-events: all;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  } 
}

@media screen and (max-width: 768px) {
  .startup-match-results {
    .match-tech-requests-inner {
      margin-left: 0;
      margin-right: 0;
    }
    .card-wrapper.match-tech-request-card {
      margin-left:0;
      margin-right: 0;
      width: 100%;
      .card.vertical {
        .card-body {
          .card-body-content {

          }
          .card-footer {
            padding:0;
            .tech-solution-match-result-card-footer { 
              border-bottom-left-radius: 8px;
              padding: 5px 24px;
              .info {
                padding: 0;
                align-items: center;
                justify-content: space-between;
              }
              .app-btn {
                padding:0;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}