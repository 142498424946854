.before-edit-notifications {
  margin-top:-2em;
  
  img.icon-warning {
    width: 40px;
    display:block;
    margin-left:auto;
    margin-right:auto;
  }

  button.app-btn {
    width: 199px;
    display:block;
    margin-left:auto;
    margin-right:auto;
  }
}

@media screen and (max-width: 768px) {
}