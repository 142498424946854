@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.service-collaboration-complete {
  width: 100%;
  height: 678px;
  .icon {
    width: 120px;
    margin-bottom: 3em;
  }
  .title {
    @extend .bold-type;
    font-size: 26px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $color_nightsky;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 768px) {
  .service-collaboration {
    height: auto;
    .title {
      text-align: center;
      font-size: 20px;
    }
    button.app-btn {
      width: 100%;
    }
  }
}