@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.main__scope-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  &>form {
    width: 100%;
    
    .input-files {
      flex-direction: column-reverse;

      .drop-area-container {
        margin-top: auto;
        flex: 0 0 25%;
      }
      .uploaded-files {
        .file {
          .name {
            line-height: 140%;
          }
        }
      }
    }
  }
}