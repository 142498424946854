// @use 'assets/styles/variables';
// @use 'assets/styles/typography';
@import '../../../../../assets/styles/variables.scss';

form.form-layout-form {
  .form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    &>label {
      font-family: $fontFamilySemiBold;
      font-size: 12px;
      letter-spacing: 0.06em;
      line-height: 100%;
      color: $color_text;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
    input[type="text"],
    input[type="email"],
    input[type="number"] {
      padding: 20px;
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      font-family: $fontFamilyMedium;
      color: $color_nightsky;
      font-size: 14px;
      line-height: 100%;
      height: 54px;
      font-weight: 500;
    }

    .input-group {
      input[type="number"] + .select-one {
        .input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    textarea {
      font-family: $fontFamilyMedium;
      font-weight: 500;
      color: $color_nightsky;
      font-size: 14px;
      line-height: 160%;
      padding: 20px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 8px;
  
      &:empty {
        background-color: #fff;
      }
  
      &::placeholder {
        background-color: #fff;
        opacity: 0.6;
        font-weight: 300;
      }
  
      &:focus-visible {
        outline: 0;
      }
  
      &.textarea-3 {
        height: 10em;
      }
    }

    .form-check {
      padding-left:0;
    }

    .delete-row {
      height: 100%;
      align-self: center;
    }

    .input-image {
      height: 167px;
    }
  }

  .add-option-row {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
  }

}

@media screen and (max-width: 500px) {
  form.form-layout-form {
    .form-field {
      .delete-row {
        display: flex;
        justify-content: center;
        button.link {
          padding:0;
          img {
            margin:0;
          }
        }
      }
    }
    .add-option-row {
      justify-content: flex-start;
    }
  }
}