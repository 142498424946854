.form-layout {
  h6 {
    text-align: left;
  }
  h1 {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .form-layout {
    h6 {
      text-align: center;
      font-size: 10px !important;
    }
    h1 {
      text-align: center;
      font-size: 32px;
      line-height: 32px;
    }
    .form-description {
      text-align: center;
      margin-top: 3rem;
    }
  }
}