@import '../../../../../assets/styles/variables.scss';

.news-card {
  border-radius: 8px;
  border:1px solid rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin: 1em;
  height: 360px;
  max-width: 382px;
  width: 30%;
  cursor: pointer;

  &:hover {
    border:1px solid $color_azure;
  }

  .image {
    flex:0 0 159px;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    img {
      display:block;
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: center center;
    }

    .price-box {
      position: absolute;
      top: 1.2em;
      right: 1.2em;
    }

    .circle-checkbox {
      position: absolute;
      top: 1.2em;
      left: 1.2em;
    }
  }

  .content {
    padding: 32px 32px;
    margin: 0;
    overflow: hidden;
    .body {
      margin-top: 16px;
      padding:0;
    }
  }

  .row-action {
    padding: 17px 32px;
    margin-top: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@media (max-width: 1440px) {
  .news-card {
    width: 45%;
  }
}

@media (max-width: 1024px) {
  .news-card {
    width: 100%;
    max-width: 100%;
  }
}