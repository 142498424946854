@import '../../../assets/styles/typography.scss';
@import '../../../assets/styles/colours.scss';

.home {
  background-color: #fff;
}

.home-body-copy {
  @extend .light-type;
  font-weight: 300 !important;
  color: $color_text !important;
  font-size: 14px;
  line-height: 160%;
}

@media screen and (max-width: 768px) {
  .home {
    background-color: #fff;
  }
  .home-nightsky {
    background-color: #17224d;
    height: 100vh;
  }
}

