@import '../../../../assets/styles/variables.scss';

.contact {
  h5 {
    font-size: 16px;
  }
}
#contact-form {
  .contact-us-form {
    h5 {
      font-size: 16px;
    }
    label.form-control-label-inside {
      position: relative;
      .text {
        position: absolute;
        top: 2em;
        left: 2em;
        z-index: 2;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-duration: .2s;
      }

      input:focus + .text,
      .text.focused {
        top: -1em;
        left: 1em;
        background-color: #fff;
        padding: 0.5em;
      }
      
      textarea:focus + .text,
      .text.textarea.focused {
        top: -0.25em;
        left: 1em;
        background-color: #fff;
        padding: 0.5em;
      }
    }
  }

  .world-map {
    display:block;
    width: 90%;
    max-width: 610px;
  }
}

@media screen and (max-width: 1023px) {
  #contact {
    scroll-margin-top: 60px;
  }
  .contact {
    h6 {
      font-size: 10px !important;
    }
    h5 {
      font-size: 14px
    }
    h1 {
      font-size: 32px;
    }
  }
  .contact {
    #contact-form {
      h5 {
        font-size: 14px
      }
      h1.sm {
        font-size: 20px !important;
      }
      label.form-control-label-inside {
        .text {
          top: 1.6em;
          left: 2em;
          font-size: 13px;
        }
      }
      button.btn-submit {
        width: 100%;
      }
    }
  }
}