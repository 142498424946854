.date-select {
  .year {
    flex: 0 0 30%;
  }
  .month {
    flex: 0 0 30%;
  }
  .date {
    flex: 0 0 30%;
  }
}

@media screen and (max-width: 768px) {
  .date-select {
    .text {
      font-size: 10px !important;
    }
  }
}