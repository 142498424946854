@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.mobile-menu {
  height: 100%;
  display: flex;
  .menu-icon {
    width: 80px;
    height: 100%;
    position: relative;
    left:0px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 18px 24px;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    &>.line {
      width: 100%;
      height:2px;
      background-color: $color_nightsky;
      transition-property: all;
      transition-duration: .3s;
      transition-timing-function: ease-in-out;
      
      &:nth-child(1) {
        transform-origin: top left;
        transform: rotate(0deg);
      }
      &:nth-child(3) {
        transform-origin: bottom left;
        transform: rotate(0deg);
      }
    }

    &.active {
      left:5px;
      &>.line:nth-child(1) {
        transform-origin: top left;
        transform: rotate(45deg);
      }
      &>.line:nth-child(2) {
        width:1px;
        opacity: 0;
      }
      &>.line:nth-child(3) {
        transform-origin: bottom left;
        transform: rotate(-45deg);
      }
    }
  }
  .menu-container {
    position: fixed;
    top: 104px;
    left: 0px;
    background-color: #fff;;
  }
}