@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/variables.scss";

.hero {
  position: relative;
  height: auto;

  .content {
    position: relative;
    z-index: 2;
    width:100%;
    height:100%;
    padding-top: 9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    h1 {
      @extend .bold-type;
      font-weight: 700;
      color: $color_nightsky;
      line-height: 119.7%;
      font-size: 80px;
      letter-spacing: -0.02em;
      text-align: center;
      margin-top:24px;
    }
    h6 {
      padding-top: 8em;
    }
    .button-col {
      padding-left:0;
      padding-right:0;
    }
    .signup-row {
      margin-bottom: 104px;
    }
  }

  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .4;
    overflow: hidden;
    video {
      pointer-events: none;
      transform:scale(1.5);
      display: block;
      height:100%;
      width:100%;
      object-fit: cover;
    }
  }

  .logo-container {
    margin-left:-15px;
    margin-right:-15px;
  }
}

@media (max-width: 1023px) {
  .hero {
    .content {
      padding-top: 4em;
      justify-content: start;
      h6 {
        font-size: 10px;
        padding-top:1.85em;
        padding-bottom: 0px;
      }
      h1 {
        font-size: 32px;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0em;
        margin-top: 0.2em;
      }
      .signup-row {
        margin-bottom: 20px;
      }
    }

    .video-background {
      video {
        position: relative;
        top: 8em;
        transform: scale(3);
      }
    }

    .bottom-gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5em;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    }
  }
}
