@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/resets.scss";

.sign-up-verification {
    .description-wrapper {
        text-align: center;
        .description {
            font-size: 16px !important;
            margin-bottom: 5px !important;
        }
    }

    .input-otp-wrapper {
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        input[type="number"] {
            @extend .base-typography;
            width: 48px;
            height: 54px;
            display: flex;
            flex-direction: row;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: $color_nightsky;
            align-items: center;
            text-align: center;

            /* White */
            background: #ffffff;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 8px;

            &:focus {
                outline: none;
            }

            /* Firefox */
            -moz-appearance: textfield;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .input-otp-button-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .sign-up-verification {
        h1 {
            font-size: 32px;
        }
    }
}