@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.need-help {
  width: 100%;
  height: 100%;
  max-height:263px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 8px;
  padding: 45px;

  &>.inner {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
    padding: 0;
    flex: 0 0 auto;
  }

  .title {
    @extend .medium-type;
    color: #fff;
    font-family: $fontFamilySemiBold;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    margin-bottom: 21px;
    img {
      margin-right: 8px;
    }
  }

  ul {
    li {
      @extend .light-type;
      color: #fff;
      list-style: none;
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-bottom: 10px;
      font-weight: 400;
      &:before {
        content: ' ';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display:block;
        margin-right: 10px;
        background-color: #fff;
      }
    }
  }

  button.app-btn {
    font-size: 13px !important;
    padding: 17.5px 28px;
    @extend .semi-bold-type;
  }
}

@media screen and (max-width:768px) {
  .need-help {    
    flex-direction: column;
    height: 229px;
    padding: 24px;
    .title {
      margin: 0;
      padding: 0;
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
      padding-right:20px;
    }
    
    ul {
      margin-left:auto;
      margin-right:auto;
      padding-left:0;
      li {
        font-size: 12px;
        line-height: 16.8px;
        font-weight: 400;
        margin-bottom: 0;
        &>img {
          display: none;
        }
      }
    }
    
    button.app-btn {
      flex: none;
      padding: 17.5px 28px;
      height: 48px;
      & > span {
        font-size: 13px !important;
        font-weight: 700;
      }
    }
  }
}