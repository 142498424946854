@import '../../../../assets/styles/variables.scss';

.main {
  padding-left: 0;
  padding-right: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: calc(100vh - 105px);
  overflow-y: auto;
  background-repeat: repeat;
  display:flex;
  flex-direction: row;
  &:before {
    content: ' ';
    width: 18.22916666666667%;
    max-width: 350px;
    min-width: 280px;
    height: 100vh;
    display: block;
  }
  &>.main-inner {
    width: 1px;
    flex: 1 1 1px;
    padding: 0 6.35%;
    padding-bottom: 60px;

    .top-back-button {
      display: none;
      margin-bottom: 16px;
      margin-left: -12px;
      .button-back {
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 5%;
        font-weight: 700;
        font-family: $fontFamilyBold;
        .icon {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .main {
    &>.main-inner {
      padding: 0 5%;
    }
  } 
}


@media (max-width: 1440px) {
  .main {
    &>.main-inner {
      padding: 0 3%;
    }
  } 
}

@media (max-width: 1024px) {
  .main {
    &>.main-inner {
      padding: 0 2%;
    }
  } 
}

@media screen and (max-width: 768px) {
  .main {
    background: rgba(246, 246, 246, 1);
    background-size: cover;
    margin-left:0;
    padding:24px 24px;
    height:auto;
    min-height: 100vh;
    overflow-y: visible;
    &>.main-inner {
      padding: 74px 0;
      .top-back-button {
        display: block;
      }
    }
    &:before {
      display: none;
    }
    &.menu-active {
      height: calc(100vh - 80px);
      overflow-y: hidden;
    }
    &>.container-fluid {
      width:100%;
    }
  }
}