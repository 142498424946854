@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.left__chat {
  .chat-button {
    width:100%;
    height: 64px;
    border-radius: 8px;
    background-color: $color_nightsky;
    b {
      color: $color_white;
    }
  }
}