.match-tech-request-card-image-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  .match-percent {
    position: absolute;
    top: 50%;
    left: 32px;
    transform: translateY(-50%,);
  }
  .favourite {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.card-wrapper.match-tech-request-card {
  max-width: 382px;
  min-width: 30%;
  flex: 1 1 30%;

  cursor: pointer;
  .card.vertical {
    .card-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;
    }
    .card-body {
      .card-body-content {
        h5 {
        }
        p {
          margin-bottom: 16px;
          max-height: 40px;
        }
      }
      .card-footer {
        padding:0;

        .tech-solution-match-result-card-footer {
          padding: 5px 24px;
          height: 100%;
          display: flex;
          flex-direction: row;
          position: relative;
          flex: 0 0 100%;
          border-bottom-right-radius: 8px;
          overflow: hidden;
          
          .info {
            visibility: visible;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-left: 8px;
            padding-right: 8px;
            .icon-label {
              margin-left: 4px;
              margin-right: 4px;
              &:first-child {
                margin-left:0;
              }
              &:last-child {
                margin-right: 0;
              }
              &.country {
                &>span {
                  max-width: 150px;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .action {
            pointer-events: none;
            visibility: hidden;
            position: absolute;
            left: 0;
            border-radius: 0;
            width: 100%;
            padding:10px 20px;
          }
        }
      }
    }

    &:hover {
      .card-body {
        .card-footer {
          .info {
            visibility: hidden;
          }
          .action {
            pointer-events: all;
            visibility: visible;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .card-wrapper.match-tech-request-card {
    min-width: 200px;
    width: auto;
    flex: 1 1 40%;
  }
}

@media screen and (max-width: 1024px) {
  .card-wrapper.match-tech-request-card {
    min-width: 100%;
    width: 100%;
    height: 290px;
    max-width: fit-content;
    flex: none;
    margin-left:0;
    margin-right: 0;
    .card.vertical {
      .card-image {
        height: 118px;
        flex: 0 0 118px;
      }
      .card-body {
        .card-body-content {
          h5 {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            font-size: 12px;
            height: 68px;
            overflow: hidden;
          }
        }
      }
      .card-footer {
        padding:0;
        height: 34px;
        flex: 0 0 34px;
        .tech-request-match-result-card-footer {
          border-bottom-left-radius: 8px;
          padding:0 24px !important; 
          .info {
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .match-tech-request-card-image-overlay {
    .match-percent {
      left: 24px;
    }
    .favourite {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
  
}