@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/resets.scss';

.input-uploads {

  display:flex;
  cursor: pointer;
  overflow: hidden; 
  label.input-area {
    width:100%;
    height: 100%;
    margin-right: auto;
    cursor: pointer;
    .input-area-inner {
        width: 100%;
        height: 100%;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
        input[type="file"] {
          cursor: pointer;
          opacity: 0;
          height: 100%;
          width: 100%;
          z-index: 5;
          position: absolute;
        }
        button.action {
          @extend .button-reset;
          display:flex;
          align-items: center;
          img {
            flex: 0 0 1px;
            margin-right: 10px;
          }
          span {
            @extend .bold-type;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $color_azure;
          }
        }
      }
  } 
  .mapped-uploads {
    text-align: left;
    .file {
      display:flex;
      align-items: center;
      margin-bottom: 18px;

      &:first-child {
        margin-top: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        flex: 0 0 15px;
        width: 13px;
        margin-right: 8px;
      }
      a {
        flex: 1 1 auto;
        max-width: fit-content;
        width: auto;
        text-decoration: none;
      }
      .name {
        @extend .bold-type;
        color: $color_azure;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
      }
      button {
        margin-left: 8px;
        flex: 0 0 15px;
        align-items: center;
        display: flex;
      }
    }
  }
}
