@import '../../../../../assets/styles/variables.scss';

.explore-help-banner {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color_nightsky;
  border-radius: 8px;
  overflow: hidden;
  min-height: 300px;
  position: relative;
  width: 100%;
  .content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 32px 24px;
    .title {
      font-size: 32px;
      font-family: $fontFamilySemiBold;
      line-height: 140%;
      color: #fff;
      text-align: center;
    }
    .text {
      font-size: 16px;
      font-family: $fontFamilyRegular;
      color: #fff;
      line-height: 140%;
      text-align: center;
    }
    .app-btn {
      min-width: unset;
    }
  }
  .image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    img {
      object-position: 50% 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .explore-help-banner {
    min-height: unset;
    .content {
      position: relative;
      .title {
        font-size: 16px;
        text-align: center;
      }
      .text {
        font-size: 12px;
        text-align: center;
      }
    }
    &>img {

    }
  }
}