@use '../../../../../../assets/styles/variables' as *;
@import '../../../../../../assets/styles/typography.scss';
@import '../../../../../../assets/styles/resets.scss';

.help {
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 50px;
  display:flex;
  
  flex-direction: column;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 40px 32px;

  .links {
    margin-top:20px;
    display: flex;
    flex-direction: column;

    .link {
      @extend .button-reset;
      font-family: $fontFamilyMedium;
      font-weight: 500;
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      img {
        margin-right: 16px;
      }
      span {
        color: $color_nightsky;
      }
    }
  }

  h6 {
    font-weight: 700;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .help {
    padding: 24px;
    margin-top: 20px;
    h6 {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
    .links {
      flex-direction: row;
      justify-content: space-around;

      .link {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;  
        padding: 0 22px;
        flex: 1 1 50%;
        img {
          width: 18px;
          margin-bottom: 8px;
          margin-right: 0;
        }
        span {
          text-align: center;
          font-size: 12px;
        }

        &:nth-child(2n + 1) {
          border-right: 1px solid rgba(108, 108, 108, 0.1);
        }
      }
    }
  }
}