@import '../../../../../../assets/styles/typography.scss';
@import '../../../../../../assets/styles/variables.scss';

.profile {
  font-family: $fontFamilyBold;
  font-size: 14px;
  display:flex;
  flex-direction: row;
  align-items: center;
  min-width:0;
  .avatar {
    border-radius: 16px;
    overflow: hidden;
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    background-color: #666;
    margin-right: 8px;
    img {
      display:block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
    img[src=""] {
      visibility: hidden;
    }
    img:not([src=""]) {
      visibility: visible;
    }
  }
  .name {
    white-space: nowrap;
    max-width: 150px;
    width: 100%;
    min-width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .profile {
    font-family: $fontFamilyMedium;
    font-size :12px;
    flex: 1 0 135px;
    max-width: 80px;
    overflow-x:hidden;
    .avatar {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
  }
  .dropdown.profile-dropdown {
    .dropdown-toggle {
      .dropdown-toggle-icon {
        width:7px;
        flex: 0 0 7px;
      }
    }
    .dropdown-menu {
      width: 204px;
      top: 55px;
      left:0;
      transform: none;
    }
  }
}