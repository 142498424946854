@import '../../../../assets/styles/resets.scss';
@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

button.button-icon {
  @extend .button-reset;
  background-color: #fff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  border:none;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  display:flex;
  align-items: center;
  padding: 24px;
  max-width: 466px;
  width:100%;
  .icon {
    background-color: $color_java;
    width: 84px;
    height: 84px;
    flex: 0 0 84px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    margin-left:24px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    text-align: left;
    h2 {
      @extend .bold-type;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.02em;
      font-weight: 700;
      color: $color_nightsky;
      margin-bottom: 8px;
    }
    p {
      color: $color_text;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1023px) {
  button.button-icon {
    padding: 20px;
    margin-right: 24px;
    margin-left: 24px;
    .icon {
      width: 68px;
      height: 69px;
      flex: 0 0 69px;
    }
    .text {
      h2 {
        font-size: 16px;
      }
    }
  }
}