@import '../../../../../assets/styles/variables.scss';

.steps-status {
  padding: 32px 60px 50px 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  margin-top: -20px;
  position: relative;
  display: flex;
  .step-nodes {
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;
    .step {
      background-color: #ffff;
      display:flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .circle {
        width: 32px;
        height: 32px;
        border: 1px solid rgba(0,0,0, 0.1);
        border-radius: 50%;
        color: $color_text;
        display:flex;
        justify-content: center;
        align-items: center;
        * {
          opacity: 0.3;
        }
      }
      label {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        color: $color_text;
        text-transform: uppercase;
        opacity: 0.3;
        white-space: nowrap;
      }
  
      &.active {
        .circle {
          border: 1px solid $color_azure;
          color: $color_azure;
          * {
            opacity: 1;
          }
        }
        label {
          color: $color_azure;
          opacity: 1;
        }
      }

      &.past {
        .circle {
          background-color: $color_azure;
          * {
            opacity: 1;
          }
        }
        label {
          color: $color_azure;
          opacity: 1;
        }
      }
    } 
  }
  .step-lines {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 0;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 32px 40px;
    .line {
      width:100%;
      height:1px;
      border-width: 1px;
      border-style: dashed;
      border-color: rgba(230,230,230, 1);
      margin-left: 35px;
      margin-right: 3px;
      &:first-child {
        margin-left: 60px;
      }
      &:last-child {
        margin-right: 60px;
      }
      &.active {
        border-color: $color_azure;
        border-style: solid;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .steps-status {
    padding: 16px 16px;
    background-color: transparent;
    box-shadow: none;
    .step-lines {
      padding: 16px 20px;
      .line {
        &:first-child {
          margin-left: 36px;
        }
        &:last-child {
          margin-right: 36px;
        }
      }
    }
  }
}