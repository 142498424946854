@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.main__nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  &>.tab-nav-item {
    cursor: pointer;
    flex: 0 0 25%;
    border:1px solid $color_grey;
    padding: 24px 29px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &>.icon {
      flex: 0 0 16px;
      position: relative;
      &>img:last-child {
        position: absolute;
        left: 0;
        top: 2px;
        visibility: hidden;
        width: 16px;
        height: 16px;
      }
    }
    &>span {
      color: $color_text;
      padding-left:10px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1%;
      font-weight: 700;
      font-family: $fontFamilySemiBold;
      text-transform: uppercase;
    }

    &.active {
      &>.icon {
        &>img:first-child {

        }
        &>img:last-child {
          visibility: visible
        } 
      }
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}