@import '../../../../../assets/styles/variables.scss';

.tech-solution-card {
  margin-bottom: 32px;
  width: 100%;
  height: 245px;

  &:last-child {
    margin-bottom: 0;
  }
  .card.horizontal {
    .card-image {
      width: 370px;
      flex: 0 0 370px;
      border-bottom-left-radius: 8px !important;
      border-top-right-radius: 0 !important;
      img {
        border-bottom-left-radius: 8px !important;
        border-top-right-radius: 0 !important;
      }
    }
    .card-body {
      .card-body-content {
        padding: 32px 56px 0 56px ;
        p {
          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .looking-for {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          &>span {
            font-size: 12px;
            text-transform: uppercase;
            margin-right: 16px;
            margin-bottom: 8px;
            font-weight: 600;
            color: $color_text;
          }
          &>div {
            display:flex;
            flex-direction: row;
            .pill {
              font-size: 11px;
            }
          }
        }
      }
    }

    .tech-solution-card-footer {
      display: flex;
      flex-direction: row;
      position: relative;
      flex: 0 0 100%;
      border-bottom-right-radius: 8px;
      overflow: hidden;
      
      .info {
        padding: 11px 56px;
        visibility: visible;
        display: block;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        .icon-label {
          margin-left:0px;
          margin-right:5%;
          float:left;
        }
      }
      .action {
        pointer-events: none;
        visibility: hidden;
        position: absolute;
        left: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
      }

    }

    &:hover {
      cursor: pointer;
      .tech-solution-card-footer {
        .info {
          visibility: hidden;
        }
        .action {
          pointer-events: all;
          visibility: visible;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .tech-solution-card {
    .card.horizontal {
      .card-image {
        width: 100%;
        flex: 1 1 40%;
      }
      .card-body {
        width: 100%;
        flex: 1 1 auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tech-solution-card {
    width: 100%;
    height: 404px;
    .card.horizontal {
      .card-image {
        width: 100%;
        height: 118px;
        flex: 0 0 118px;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
        img {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 8px !important;
          border-top-right-radius: 8px !important;
        }
      }
      .card-body {
        width: 100%;
        flex: 1 1 auto;
        .card-body-content {
          padding-bottom: 16px !important;
          .card-title {
            height: 40px;
            overflow: hidden;
            margin: 0px 0px 8px 0px;
          }
          p {
            overflow: hidden;
            height: 34px;
            margin:0 0 16px 0;
          }
          .looking-for {
            display: block;
            &>span {
              font-size: 10px;
              display: block;
              clear:both;
            }
            &>div {
              &>.pill {
                float:left;

                &:nth-child(n + 3) {
                  display: none;
                }
              }
            }
          }
        }
      }
      .card-footer {
        padding: 0;
      }
      .tech-solution-card-footer {
        border-bottom-left-radius: 8px;
        .info {
          padding:0px 16px;
          display:grid;
          grid:
            [r1-start] "country country maturity maturity" [r2-end]
            [r-border-start] "seperate seperate seperate seperate" [r-border-end]
            [r2-start] "last-update rating rating views" [r2-end]
            / 30% 15% 15% 30%
          ;
          &>.icon-label {
            padding:0px;
            margin: 11px 0;
            .icon {
              height:auto;
            }
          }
          .icon-label.country {
            grid-area: country;
          }
          .horizontal-border {
            display: block;
            grid-area: seperate;
            margin-left: -16px;
            margin-right: -16px;;
            border-bottom:1px dashed rgba(0,0,0, .2);
          }
          .icon-label.maturity {
            grid-area: maturity;
            justify-self: flex-end;
          }
          .icon-label.last-update {
            grid-area: last-update;
          }
          .icon-label.rating {
            grid-area: rating;
            justify-self: center;
          }
          .icon-label.views {
            grid-area: views;
            justify-self: flex-end;
          }
        }
      }
    }
  }
}