@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.service__collaboration-details {
  display:flex;
  flex-direction: row;
  &>.left {
    flex: 0 0 280px;
  }
  &>.right {
    margin-left: 41px;
    flex: 1 1 100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;

    &>.top {
      width: 100%;
    }

    &>.main-area {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
}