@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/resets.scss';
@import '../../../../assets/styles/variables.scss';

.input-inline {
  display:flex;
  align-items: center;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: ease-in;
  span.display {
    @extend .bold-type;
    color: $color_nightsky;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.02em;
    min-width: 200px;
    width: auto;
    max-width: 100%;
  }
  .input-set {
    display:flex;
    position: relative;
    height: 28px;
    min-width:1px;

    input[type="text"] {
      @extend .bold-type;
      color: $color_nightsky;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.02em;
      border: none;
      outline: none;
      width: 100%;
      min-width:2em;
      padding: 0 .25em 0 .25em;
      margin: 0;
      border-radius: 0;
      position: absolute;
      z-index: 2;
      transition-property: all;
      transition-duration: .1s;
      transition-timing-function: ease-in;
      &:focus {
        outline: none;
      }
    }

    .text-width-render {
      @extend .bold-type;
      white-space: nowrap;
      color: transparent;
      pointer-events: none;
      font-style: normal;
      font-weight: bold;
      min-width:2em;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.02em;
      padding-left:0.25em;
      padding-right:0.25em;
      transition-property: all;
      transition-duration: .1s;
      transition-timing-function: ease-in;
    }
  }
  
  button.edit {
    @extend .button-reset;
    display:flex;
    align-items: center;
    margin-left:0.25em;
    label {
      @extend .base-typography;
      margin:0;
      margin-left:7px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $color_azure;
    }
  }
}

@media screen and (max-width: 768px) {
  .input-inline {
    .text-width-render {
      overflow: hidden;
    }
  }
}