.card-plus {
  display:flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  height: 422px;
  margin-left: 16px;
  margin-right: 16px;
  img.icon {
    width: 94px;
    height: 94px;
  }
}