@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.assigned-catalyst {
  display: flex;
  flex-direction: row;
  .image {
    width: 106px;
    height: 106px;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center center;
      display:block;
      width:100%;
      height: 100%;
    }
  }
  .body {
    @extend .base-typography;
    font-size: 14px;
    line-height: 19.6px;
    display:flex;
    flex-direction: column;
    padding-left: 24px;
    color: $color_text;
    h3 {
      margin: 0;
    }
    .content {
      margin-left: 0;
      margin-top: 16px;
    }
    .link {
      margin-top: auto;
      @extend .semi-bold-type;
      font-size: 14px;
      line-height: 19.6px;
      text-align: left;
      display:inline;
      color: $color_azure;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  .assigned-catalyst {
    .image {
      width: 88px;
      height: 88px;
      flex: 0 0 88px;
    }
    .body {
      h3 {
        font-size: 14px !important;
      }
      .content {
        font-size: 12px !important;
      }
      .link {
        font-size: 12px;
      }
    }
  }
}