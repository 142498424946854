@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/colours.scss';

.chat-widget-component {
  button.chat-button {
    background-color: #17224d;
    color: white;
    margin-bottom: 32px;
    margin-top: 32px;
    width: 100%;
    border: 1px black;
    text-align: center;
    padding-right: 24px !important;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    
    img {
      transition-property: all;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
    }
    
    .label {
      font-family: $fontFamilyBold;
      padding-left: 8px;
      color: #fff;
      flex: 1 1 100%;
      text-align: left;
    }

    &:hover {
      img:last-child {
        transform: translateX(4px);
      }
    }
  }
}