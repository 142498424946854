@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/resets.scss';

.call-to-action {
  display:flex;
  flex-direction: column;

  .links {
    margin-top:20px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .link {
      margin-left: 22px;
      margin-right: 22px;
      @extend .button-reset;
      font-family: $fontFamilyMedium;
      font-weight: 500;
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      img {
        margin-right: 16px;

      }
    }
  }

  h5 {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

}

@media screen and (max-width:768px) {
  .call-to-action {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    h5 {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
    .links {
      justify-content: space-around;

      .link {
        margin:0;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;  
        padding: 0 44px;
        flex: 1 1 50%;
        img {
          width: 18px;
          margin-bottom: 8px;
          margin-right: 0;
          padding-bottom: 4px;
        }
        span {
          text-align: center;
          font-size: 12px;
          align-self: flex-start;
        }

        &:nth-child(2n + 1) {
          border-right: 1px solid rgba(108, 108, 108, 0.1);
        }
      }
    }
  }
}