@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/resets.scss';

.input-files {
  display:flex;
  flex-direction: column;
  .drop-area-disabled {
    flex: 1 1 100%;
  }
  .drop-area-container {
    flex: 1 1 100%;
    display:flex;
    flex-direction: column;
    .drop-area {
      flex: 1 1 100%;
      display:flex;
      position: relative;
      background-color: rgba(255,255,255, 0.1);
      border: 1px solid $color_grey;
      overflow: hidden;
      border-radius: 8px;
      min-height: 140px;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: .3s;
      cursor: pointer;

      .uploading-progress {
        position: absolute;
        z-index: 2;
        width:100%;
        height:100%;
        background-color: rgba(0,0,0, .6);
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      label.input-area {
        cursor: pointer;
        padding: 20px;
        width:100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        margin:0;
        display:block;
        .input-area-inner {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          input[type="file"] {
            opacity: 0;
            height: 100%;
            width: 100%;
            z-index: 5;
            position: absolute;
            cursor: pointer;
          }
          button.action {
            @extend .button-reset;
            display:flex;
            align-items: center;
            img {
              flex: 0 0 1px;
              margin-right: 10px;
            }
            span {
              @extend .base-typography;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 100%;
              text-transform: uppercase;
              color: $color_nightsky;
            }
          }
        }
      }

      &.on-drag {
        background-color: rgba(0,0,0, 0.07);
        box-shadow: 0 0 2px 2px $color_azure_light;
      }
      
      &:hover {
        background-color: rgba(0,0,0, .2);
      }
    } 
  }

  .uploaded-files {
    .file {
      display:flex;
      align-items: center;
      margin-bottom: 18px;

      &:first-child {
        margin-top: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        flex: 0 0 15px;
        width: 13px;
        margin-right: 8px;
      }
      a {
        flex: 1 1 auto;
        max-width: fit-content;
        width: auto;
        text-decoration: none;
      }
      .name {
        @extend .bold-type;
        color: $color_azure;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
      }
      button {
        margin-left: 8px;
        flex: 0 0 15px;
        align-items: center;
        display: flex;
      }
    }
  }
}