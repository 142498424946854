@import '../../../../../assets/styles/variables.scss';

.left__person {
  margin-top: 24px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
      object-fit: cover;
      margin-top: -2%;
  }

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }

  hr {
    height:1px;
    width: 100%;
    color: $color_text;
  }
}
