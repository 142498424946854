.project-budget-corporate {
  button.remove {
    border:none;
    background:transparent;
    width: 40px;
    height: 40px;
    img {
      display:block;
      width:100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .project-budget-corporate {
    .text {
      font-size: 10px;
    }
  }
}