@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.early-access {
  width: 100%;
  .icon {
    width: 120px;
    margin-bottom: 3em;
  }
  .title {
    @extend .bold-type;
    font-size: 18px;
    line-height: 150%;
    color: $color_nightsky;
    margin-bottom: 1em;
  }
}