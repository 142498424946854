@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.main__structure-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  &>form {
    width: 100%;

    .auto-height {
      display: grid;
      width: 100%;

      &:after {
        content: attr(data-replicated-value) " ";

        /* This is how textarea text behaves */
        white-space: pre-wrap;

        /* Hidden from view, clicks, and screen readers */
        visibility: hidden;
      }

      &>textarea {
        resize: none;
        overflow: hidden;
      }

      &>textarea,
      &:after {
        /* Identical styling required!! */
        font-family: inherit;
        color: $color_nightsky;
        font-size: 14px;
        line-height: 160%;
        font-weight: 300;
        padding: 20px;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 8px;
        
        /* Place on top of each other */
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    button.remove {
      width:auto;
      margin-left: 8px;
      min-width: 0;
      img {
        width:20px;
      }
    }

  }
}