.logo-row {
  background-color: rgba(255,255,255, 0.5);
  display:flex;
  justify-content: center;
  .logos {
    width: 100%;
    max-width: 1700px;
    display: flex;
    flex-direction: row;
    padding-top:3em;
    padding-bottom:3em;
    padding-left: 7em;
    padding-right: 7em;
    .part {
      flex: 0 0 50%;
      width: 50%;
      display:flex;
      padding-right:1.2em;
      padding-left:1.2em;
      justify-content: space-between;
      align-items: center;
    }
    img {
      max-height: 70px;
      max-width: 20%;
      filter:grayscale(1) opacity(.5);
    }
  }
}

@media screen and (max-width: 1023px) {
  .logo-row {
    .logos {
      padding-top: 1em;
      padding-bottom: 1em;
      padding-left: 2em;
      padding-right: 2em;
      .part {
        width: 100%;
        display:flex;
        justify-content: space-between; 
        padding-right:1.5%;
        padding-left:1.5%;
        &:first-child {
          padding-right:1%;
        }
        &:last-child {
          padding-left:1%
        }
      }
      img {
        object-fit: contain;
        max-height: 40px;
        max-width: 20%;
        width: auto;
        margin:10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .logo-row {
    .logos {
      flex-direction: column;
      .part {
        width: 100%;
        display:flex;
        justify-content: space-between; 
        padding:0;
      }
      img {
        object-fit: contain;
        max-height: 40px;
        max-width: 25%;
        width: auto;
        margin:10px;
      }
    }
  }
}