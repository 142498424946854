.news-detail {
  .image-top {
    height: 451px;
    margin-left: -40px;
    margin-right: -40px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    margin-top: -40px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    img {
      width: 100%;
    }
  }
}