.tech-solution-form-grid {
  display: grid;
  grid-template:
    [title-start] "title title title" auto [title-end]
    [action-top-start] "action-top action-top action-top" auto [action-top-end]
    [row-1-start] "photo tech-background tech-background" auto [row-1-end]
    [row-2-start] "tech-type tech-type industries" auto [row-2-end]
    [row-3-start] "departments departments business-use-case" auto [row-3-end]
    [row-4-start] "sdg-objectives sdg-objectives business-use-case" auto [row-4-end]
    [row-5-start] "pricing pricing need-help" auto [row-5-end]
    [row-6-start] "options options demo-area" auto [row-6-end]
    [row-7-start] "testimonials testimonials testimonials" auto [row-7-end]
    [row-8-start] "case-studies case-studies case-studies" auto [row-8-end]
    [row-9-start] "selling-info selling-info selling-info" auto [row-9-end]
    [row-10-start] "invest-info invest-info invest-info" auto [row-10-end]
    [action-bottom-start] "action-bottom action-bottom action-bottom" auto [action-bottom-end]
    / 30% 18% auto;
  gap: 32px;

  .field-title {
    grid-area: title;
  }
  .action-top {
    grid-area: action-top;
  }
  .action-bottom {
    grid-area: action-bottom;
  }
  .field-image {
    grid-area: photo;
    height: 398px;
    .input-image {
      height: 398px;
      .input-area {
        height: 398px;
      }
    }
  }
  .field-tech-background {
    grid-area: tech-background;
    align-self: start;
    textarea {
      height: 81px;
    }
  }
  .field-tech-type {
    grid-area: tech-type;
  }
  .field-industries {
    grid-area: industries;
  }
  .field-business-use-case {
    grid-area: business-use-case;
  }
  .field-departments {
    grid-area: departments;
  }
  .field-pricing {
    grid-area: pricing;
  }
  .field-need-help {
    grid-area: need-help;
  }
  .field-sdg-objectives {
    grid-area: sdg-objectives;
  }
.field-options {
  grid-area: options;
}
.field-demo {
  grid-area: demo-area;
}
.field-testimonials {
  grid-area: testimonials;
}
.field-case-studies {
  grid-area: case-studies;
}
.field-selling-info {
  grid-area: selling-info;
}
.field-invest-info {
  grid-area: invest-info;
}
}

@media screen and (max-width: 768px) {
  
  .tech-solution-form-grid {
    display:flex;
    flex-direction: column;
    gap:24px;

    .action-row-top {
      .actions {
        display:none !important;
      }
    }
    
    .field-tech-background {
      grid-area: tech-background;
      align-self: start;
      width: 100%;
      textarea {
        height: 81px;
      }
    }

    .action-row-bottom {
      .actions {
        width: 100% !important;
        margin-left:0 !important;
        button {
          width: 100%;
        }
      }
    }
  }
}