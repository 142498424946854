@import "./variables.scss";

.color-white {
  color: #fff;
}

.color-azure {
  color: $color_azure !important;
}

.color-java {
  color: $color_java !important;
}

.color-purple {
  color: $color_purple !important;
}

.color-tangerine {
  color: $color_tangerine !important;
}

.color-nightsky {
  color: $color_nightsky !important;
}

.color-text {
  color: $color_text !important;
}

.color-grey-light {
  color: $color_grey_light2 !important;
}

.color-grey-dark {
  color: $color_grey_dark !important;
}

.color-bg-white {
  background-color: #fff;
}

.color-bg-white-opacity-50 {
  background-color: rgba(255,255,255, .5);
}

.color-bg-grey {
  background: $color_grey;
}

.color-bg-grey_opacity_20 {
  background-color: rgba(230, 230, 230, .2);
}

.color-bg-java-opacity-10 {
  background-color: $color_java_opacity_10 !important;
}

.color-bg-nightsky {
  background-color: $color_nightsky !important;
}

.color-bg-azure {
  background-color: $color_azure !important;
}

.color-bg-azure-opacity-5 {
  background-color: $color_azure_opacity_5 !important;
}

.color-bg-purple {
  background-color: $color_purple !important;
}

.color-bg-purple-opacity-5 {
  background-color: $color_purple_opacity_5 !important;
}

.color-bg-java {
  background-color: $color_java !important;
}

.color-bg-java-opacity-5 {
  background-color: $color_java_opacity_5 !important;
}

.color-bg-tangerine {
  background-color: $color_tangerine !important;
}

.color-bg-tangerine-opacity-5 {
  background-color: $color_tangerine_opacity_5 !important;
}