@import '../../../../assets/styles/variables.scss';

.chat-component__body__send-form {
  flex: 0 0 auto;
  .input-box {
    padding: 8px;
    border-radius: 8px;
    border:1px solid $color_grey;
    display: flex;
    textarea {
      flex: 1 1 auto;
      border:none;
      outline:none;
      padding:12px 24px;
      resize: none;
      height: 100%;
    }
    button {
      justify-self: flex-end;
    }
  }
}