@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/typography.scss';

#faqs {
  h1.faq {
    @extend .bold-type;
    font-size: 32px;
    line-height: 57.46px;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: $color_nightsky;
  }
}

@media screen and (max-width: 1024px) {
  #faqs {
    h1.faq {
      font-size: 16px !important;
    }
  }
}