@import '../../../../assets/styles/forms.scss';

.input-money {
  position: relative;
  @extend .input-text;
  display:flex;
  padding:0 !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  input {
    padding: 13px 0 13px 13px !important;
    border:none !important;
    width:100%;

    &:focus,
    &:focus-visibie {
      border:none;
      outline:none;
    }
  }
}
.smallWidth{
  position: relative;
  @extend .input-text;
  display:flex;
  padding:0 !important;
  input {
    padding: 13px 0 13px 13px;
    border:none;
    border-radius: 8px;
    width:100px;

    &:focus,
    &:focus-visibie {
      border:none;
      outline:none;
    }
  }

}
.zero-border{
  input[type="text"] {
    border: 0!important;
  }
}