@use '../../../../../assets/styles/variables' as *;
@import '../../../../../assets/styles/resets.scss';
@import '../../../../../assets/styles/typography.scss';

.tile {
  max-width: 1290px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  
  &.is-carousel {
    display: flex;
    
    .left, 
    .right {
      flex: 0 0 60px;
      background-color: rgba(230, 230, 230, 0.3);
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 10px;
      }
    }

  }

  .inner {
    flex: 1;
    padding:40px 40px;
    .row {
      margin:0;
      margin-bottom:40px;
      h4 {
        display:inline-block;
        width:auto;
        margin:0;
        padding:0;
      }
      p {
        @extend .txt-regular;
        padding-left: 0 !important;
        margin-left: 0 !important;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        margin-top: 1.2em;
      }
      .actions {
        width:auto;
        margin-left:auto; 
      }
      &.header {
        position: relative;
      }
      &.footer {
        display:none;
      }
    }
    .pagination {
      margin-top:40px;
      height:20px;
      display:flex;
      justify-content: center;
      align-items: center;
      button.point {
        @extend .button-reset;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-left: 4px;
        margin-right: 4px;
        opacity: 0.3;
        border: 2px solid #6C6C6C;
        box-sizing: border-box;
        &.active {
          background-color: $color_azure;
          border: 2px solid $color_azure;
        }
      }
    }
    .body {
      .body-inner {
      }
      &.carousel {
        display:flex;
        height: inherit;
        overflow: hidden;
        flex-wrap: nowrap;
        width: 100%;
        .carousel-scrollable {
          display:flex;
          min-width:100%;
          height: 100%;
          &> * {
            display: inline-block;
            min-width: 100%;
          }
          &>.card-plus {
            display:flex;
            min-width: 97.5%;
          }
        }
      }
    }
  }
}

@media (max-width:768px) {
  .tile {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 24px;
    border-radius: 16px;
    
    .inner {
      padding:32px 24px;

      h4 {
        font-size: 16px;
      }
      
      .row {
        &.header {
          height: auto;
          margin-bottom:16px;
          .actions {
            display:none;
          }
        }
        &.footer {
          background-color: transparent;
          background-image: none;
          display:block;
          margin-bottom:0;
          .actions {
            padding-left:0;
            padding-right:0;
            padding-top: 8px;
            button {
              width: 100%;
            }
          }
        }
      }

      .pagination {
        margin-top:auto;
        button.point {
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }
    }

    &.is-carousel {
      height: auto;
      position: relative;
      .left,
      .right {
        position: absolute;
        background-color: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        width: 48px;
        height: 48px;
        display:flex;
        align-content: center;
        justify-content: center;
        z-index: 10;
        img {
          width: 8px;
        }
      }
      .left {
        left: 0px;
        top: 45%;
      }

      .right {
        right: -10px;
        top: 45%;
        position: absolute;
      }
    }
  }
}