$fontFamilyLight: 'Poppins-Light', sans;
$fontFamilyMedium: 'Poppins-Medium', sans;
$fontFamilyRegular: 'Poppins-Regular', sans;
$fontFamilySemiBold: 'Poppins-SemiBold', sans;
$fontFamilyBold: 'Poppins-Bold', sans;

$color_nightsky: rgba(23, 34, 77, 1); //#17224D
$color_form_label: rgba(108,108,108, 1); //#6C6C6C
$color_azure: rgba(0, 127, 255, 1); //#007FFF
$color_azure_opacity_5: rgba(0, 127, 255, 0.05); //#007FFF
$color_azure_opacity_015: rgba(0, 127, 255, 0.15); //#007FFF
$color_azure_light: rgba(0, 127, 255, 0.2); //#007FFF
$color_text: rgba(108,108,108, 1); //#6C6C6C
$color_text_darker: rgb(45,45,45);
$color_java: rgba(23, 233, 157, 1); //#17E99D
$color_java_opacity_10: rgba(23, 233, 157, .1);
$color_java_opacity_5: rgba(23, 233, 157, .05);
$color_grey: rgba(230, 230, 230, 1); //#E6E6E6
$color_grey_light2: rgba(233,233,233, 1);//#e9e9e9
$color_grey_light3: rgb(244,244,244);
$color_grey_light4: rgb(196, 196, 196);
$color_grey_light: rgba(78, 78, 78, 1); //#4E4E4E
$color_grey_dark: rgba(78, 78, 78, 1);
$color_tangerine: rgba(255, 131, 98, 1); //#FF8362;
$color_tangerine_opacity_5: rgba(255, 131, 98, 0.05); //#FF8362;
$color_porcelain: rgba(245, 250, 250, 1); //#F5FAFA;
$color_danger: rgba(255, 131, 98, 1); //#FF8362;
$color_white: rgba(255, 255, 255, 1); //#FFFFFF
$color_purple: rgba(187, 107, 217, 1);
$color_purple_opacity_5:  rgba(187, 107, 217,  0.05) ;

$color_input_border: rgba(230, 230, 230, 1);

// $input-border-color: rgba(230, 230, 230, 1);

