.favourites {
  .match-tech-requests-inner {
    gap:32px;
    .card-wrapper {
      &.match-tech-request-card {
        width:363px;
      }
    }
  }
  .match-tech-solutions-inner {
    gap:32px;
    margin-left: -16px;
    margin-right: -16px;
    .card-wrapper {
      &.match-tech-solutions-card {
        width:363px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .favourites {
    .match-tech-requests-inner {
      margin-left: 0;
      margin-right: 0;
      .card-wrapper.match-tech-request-card {
        width: 100%;
      }
    }
    .match-tech-solutions-inner {
      margin-left: 0;
      margin-right: 0;
      .card-wrapper.match-tech-solution-card {
        width: 100%;
      }
    }
    .card-wrapper .card .card-body .card-footer {
      padding:0 !important;
      .tech-solution-match-result-card-footer {
        padding: 11px 24px;
      }
    }
  } 
}