@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.table {
  thead {
    th {
      td {
        font-size: 14px;
        line-height: 160%;
        color: $color_nightsky;
        padding-top:1.5em;
        padding-bottom:1.5em;
      }
    }
  }
  tbody {
    border-top: 1px solid rgba(0,0,0, .1);

    tr {
      td {
        @extend .medium-type;
        font-size: 14px;
        line-height: 160%;
        color: $color_nightsky;
        padding-top:1.5em;
        padding-bottom:1.5em;
        border-color: rgba(0,0,0, .1) !important;

        &:last-child {
          text-align: right;
        }
      }
    }
  }
  tfoot {
    tr {
      &:last-child,
      &:first-child {
        td {
          padding-top:1.5em;
          padding-bottom:1.5em;
          border-bottom:none;
        }
      }
    }
  }
}