@use '../../../../../assets/styles/variables' as *;
@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/resets.scss';

.expand {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img:first-child {
      width: 24px;
      margin-right: 16px;
    }
    span {
      @extend .bold-type;
      color: $color_nightsky;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
    }
    .btn-expand-toggle {
      @extend .button-reset;
      margin-left: 16px;
      position: relative;
      width: 20px;
      height: 20px;
      display:flex;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 20px;
        &.not-expanded {
          visibility: visible;
        }
        &.expanded {
          visibility: hidden;
        }
      }
    }
  }

  .children {
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    display: none;
    flex-wrap: wrap;
    flex-direction: row;
  }


  &.expanded {
    .children {
      margin-top: 32px;
      display: flex;
    }
    .controls {
      .btn-expand-toggle {
        img {
          &.not-expanded {
            visibility: hidden;
          }
          &.expanded {
            visibility: visible;
          } 
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .expand {
    padding: 16px;
    margin-bottom: 16px;
    .controls {
      justify-content: flex-start;
      img:first-child {
        width: 16px;
        margin-right: 16px;
      }
      span {
        font-size: 12px;
      }
      .btn-expand-toggle {
        margin-left:auto;
      }
    }
  }
}