.home {
  background-color: #fff;
}

.page-content {
  padding-top: 7.5em;
}

@media screen and (max-width: 500px) {
  .home {
    background-color: #fff;
  }
  .home-nightsky {
    padding: 10px;
    background-color: #17224d;
    height: 100vh;
  }
}
