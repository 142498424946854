@import '../../../../../assets/styles/variables.scss';

.app-service-card {
  border-radius: 8px;
  border:1px solid rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  margin: 1em;
  cursor: pointer;

  &:hover {
    border:1px solid $color_azure;
  }

  .image {
    flex:0 0 180px;
    object-fit: cover;
    object-position: center center;
    overflow: hidden;
    position: relative;

    img {
      display:block;
      width:100%;
      height:100%;
    }

    .price-box {
      position: absolute;
      top: 1.2em;
      right: 1.2em;
    }

    .circle-checkbox {
      position: absolute;
      top: 1.2em;
      left: 1.2em;
    }
  }

  .content {
    padding: 20px 32px;
    margin: 0;
    .body {
      padding:0;
    }
  }
}

@media screen and (max-width:768px) {
  .app-service-card {
    flex: 1 1 100%;
  }
}