@import '../../../../../assets/styles/typography.scss';

.certify {
  padding: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  .body {
    display:flex;
    flex-direction: row;
    flex: 1 1;
    &>span {
      color: #fff;
      @extend .base-typography;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 600;
    }

    &>img.certificate-icon {
      width: 25px;
      margin-right: 12px;
    }
  }

  button {
    white-space: nowrap;
  }
}