@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/resets.scss";
@import "../../../../assets/styles/variables.scss";

//created classes
.grayBoard {
    margin-top: 33px;
    padding: 24px;
    // background-color: rgb(248, 248, 248);
    background-color: #FCFCFC;
}
.checkBody{
    color: #17224D;
    padding-top: 12px;
}
.webLinks{
    &:first-child {
        margin-top: 18px;
    }
    label{
        margin-top: 16px;
    }
}
.chip{
    padding-top: 16px;
    float: left;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    // max-width: 303px;
    align-items: center;
    // justify-content: space-between;
}
.subBody {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #17224D;
    min-height: 32px;
    padding-top: 16px;
    span{
        color:#007FFF;  
    }
}

.grayHeader {

    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 100%;
    font-weight: 500;
    color: #6C6C6C;
    font-family: $fontFamilyMedium;
    margin-bottom: 8px;

}

.whiteBox {
    // background: url(partner.jpg);
    // background-color: white;
    max-width: 100%;
    max-height: 100%;
    padding:12px;

    img {
        // height: 360px;
        // width: 360px;
        display: block;
        width: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

//copied from other files
.section {
    margin-top: 40px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 40px;

    h6 {
        color: $color_nightsky;
        font-family: $fontFamilyBold;
        margin-bottom: 1%;
    }

    p {
        margin-bottom: 3%;
        margin-right: 10%;
    }

    svg {
        color: $color_azure;
        width: 8%;
        margin-right: 2%;
    }

    input {
        width: 90%;
        background-color: white;
        @extend .base-typography;
    }

    textarea {
        width: 100%;
        // margin-bottom: 3%;
        resize: none;
        @extend .base-typography;
    }
    label{
        display: block;
    }
}

.body {
    @extend .base-typography;
    font-size: 14px;
    line-height: 19.6px;
    display: flex;
    flex-direction: column;
    // padding-left: 24px;
    color: $color_text;

    h3 {
        margin: 0;
    }

    .last {
        margin-bottom: 1em;
    }

    // .link-extra {
    //     margin-top: auto;
    //     @extend .bold-type;
    //     font-size: 14px;
    //     line-height: 19.6px;
    //     text-align: left;
    //     display: block;
    //     color: $color_azure;
    //     margin-bottom: 0.7em;
    // }
}

.proBoard {
    margin-top: 50px;
    // background-color: rgb(248, 248, 248);
    background-color: #FCFCFC;
}

.sideBySideProfile {
    margin-top: 50px;
    // margin-right: 5%;
    // background-color: rgb(248, 248, 248);
    background-color: #FCFCFC;
}