.startup-packages {
  .h-line {
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 3em;
    &:before {
      content: '';
      display: block;
      width: 20%;
      border-top: 1px solid rgba(0,0,0, .1);
      margin-top: .55em;
      margin-right: 3em;
    }
    &:after {
      content: '';
      display: block;
      width: 20%;
      border-top: 1px solid rgba(0,0,0, .1);
      margin-top: .55em;
      margin-left: 3em;
    }
  }
  .item-subscribe {
    padding-left:7em;
    padding-right:7em;
  }
}

@media screen and (max-width:768px) {
  .startup-packages {
    .h-line {
      margin-top:1em;
      margin-bottom:1em;
      flex-direction: row;
      align-items: center;
      &:before {
        margin-top: .1em;
        margin-right: 1em;
      }
      &:after {
        margin-top: .1em;
        margin-left: 1em;
      }
    }
    .item-subscribe {
      padding-left: 0;
      padding-right:0;
      button.app-btn {
        width: 100%;
      }
    }
  }
}