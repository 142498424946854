.startup-profile-form {
  display:grid;
  grid-template:
    [title-row-start] "title title" [title-row-end]
    [row-1-start] "photo company-details" [row-1-end]
    [row-2-start] "website company-details" [row-2-end]
    [row-3-start] "website personal-info" [row-3-end]
    [row-4-start] "website personal-info" [row-4-end]
    [action-row-start] "action action" [action-row-end]
    / 382px auto;
  gap: 32px;

  .title {
    grid-area: title;
    display: flex;
  }

  .photo {
    grid-area: photo;
    .input-image {
      height: 332px;
    }
  }

  .company-details {
    grid-area: company-details;
  }

  .personal-info {
    grid-area: personal-info;
  }

  .website {
    grid-area: website;
    .form-control-group {
      height:unset;
    }
  }
  
  .action {
    grid-area: action;
  }

  .investment-received-input {
    input[type="text"] {
      width: 40%;
      flex: 1 1 40%;
    }
  }
}

@media screen and (max-width: 768px) {
  .startup-profile-form {
    display: flex;
    flex-direction: column;

    .photo {
      .input-image {
        height: 284px;
      }
    }

    h6 {
      font-weight: 700;
    }
    .action {
      button {
        width: 100%;
      }
    }
  }
}