@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/resets.scss';

.input-image {
  display:flex;
  cursor: pointer;
  position: relative;
  background-color: rgba(0,0,0, .2);
  overflow: hidden;
  border-radius: 8px;
  flex: 1 1 auto;
  position: relative;
  &.on-drag {
    background-color: rgba(0,0,0, 0.07);
    box-shadow: 0 0 2px 2px $color_azure_light;
  }
  label.input-area {
    width:100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    margin:0;
    display:block;
    .input-area-inner {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      input[type="file"] {
        cursor: pointer;
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
        position: absolute;
      }
      button.action {
        @extend .button-reset;
        display:flex;
        align-items: center;
        img {
          flex: 0 0 1px;
          margin-right: 10px;
        }
        span {
          @extend .bold-type;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
  }
  .loading {
    width: auto;
    height: auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .preview {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      &[src=""] {
        visibility: hidden;
      }
    }
  }

  &.elipse-left {
    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    label.input-area {
      cursor: pointer;
      position: relative;
      padding-left: 12px;
      flex: 0 0 155px;
      margin-right: auto;
      .input-area-inner {
        button.action {
          img {

          }
          span {
            color: $color_azure;
          }
        }
      }
    }
    .loading {
      width:auto;
      height: 100%;
      left: .22em;
      top: .22em;
      transform: translate(0,0);
    }
    .preview {
      flex: 0 0 auto;
      border-radius: 50%;
      background-color: rgba(0,0,0, .2);
      width: 72px;
      height: 72px;
      // margin-right:16px;

      img {
        border-radius: 50%;
      }

      img[src=""] {
        display:none;
      }
    }
  }

  &.vertical {
    flex-direction: column;
  }

  &.minimal {
    cursor: default;
    background-color: transparent;
    label.input-area {
      .input-area-inner {
        width:auto;
        button.action {
          position: relative;
          transform: translate(15%,15%);
          @extend .button-reset;
          display:flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          width: 30px;
          height: 30px;
          border-radius: 15px;
          img {
            display:block;
          }
        }
      }
    }
    .loading {
      width:auto;
      height: 100%;
      left: .22em;
      top: .22em;
      transform: translate(0,0);
    }
    .preview {
      flex: 0 0 auto;
      border-radius: 50%;
      background-color: rgba(0,0,0, .2);
      // margin-right:16px;

      img {
        border-radius: 50%;
      }

      img[src=""] {
        display:none;
      }
    }
  }
}
//new edits
.gray-image {
  display:flex;
  cursor: pointer;
  position: relative;
  background-color: rgba(0,0,0, .2);
  overflow: hidden;
  border-radius: 8px;
  flex: 1 1 auto;
  position: relative;
  &.on-drag {
    background-color: rgba(0,0,0, 0.07);
    box-shadow: 0 0 2px 2px $color_azure_light;
  }
  .loading {
    width: auto;
    height: auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .preview {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
      &[src=""] {
        visibility: hidden;
      }
    }
  }

  &.elipse-left {
    display:flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-bottom: 16px;

    .loading {
      width:auto;
      height: 100%;
      left: .22em;
      top: .22em;
      transform: translate(0,0);
    }
    .preview {
      border-radius: 50%;
      background-color: rgba(0,0,0, .2);

      img {
        border-radius: 50%;
      }

      img[src=""] {
        display:none;
      }
    }
  }

  &.circle-left {
    display:flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: left;
    background-color: transparent;
    margin-bottom: 16px;
   
    .loading {
      width:auto;
      height: 100%;
      left: .22em;
      top: .22em;
      transform: translate(0,0);
    }
    .preview {
      border-radius: 50%;
      background-color: rgba(0,0,0, .2);

      img {
        border-radius: 50%;
      }

      img[src=""] {
        display:none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .input-image {
    label.input-area {
      .input-area-inner {
        button.action {
          span {
            font-size: 12px;
          }
        }
      }
    }
    &.elipse-left {
      .preview {
        width: 48px;
        height: 48px;
      }
    }
  }
}