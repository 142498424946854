@import '../../../../assets/styles/variables.scss';

.chat-message__item {
  display:flex;
  flex-direction: column;
  align-self: flex-end;
  width:428px;
  margin-bottom: 48px;

  .chat-message__item__body {
    display: flex;
    flex-direction: row;
    
    .chat-message__item__body__person {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      flex: 0 0 32px;
      align-self: flex-end;
      img {
        object-fit: cover;
        object-position: 50% 50%;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color_grey;
      }
    }
    .chat-message__item__body__message {
      flex: 1 1 auto;
      position: relative;
      &>div {
        padding: 28px 50px 28px 40px;
        border-radius: 32px;
        background-color: $color_azure_opacity_015;
        width: 100%;
        height: auto;
        color: $color_nightsky;
        font-size: 14px;
        font-family: $fontFamilyMedium;
        font-weight: 400;
        position: relative;
        right: 9px;
      }
      &>img {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .chat-message__item__footer {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-right: 32px;
    margin-top: 12px;
    &>span {
      font-size: 12px;
      font-family: $fontFamilyLight;
      color: $color_text_darker;
    }
    &>span:nth-child(2) {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  &.other {
    align-self: flex-start;

    .chat-message__item__body {
      flex-direction: row-reverse;
      .chat-message__item__body__message {
        
        &>div {
          background-color: $color_grey_light3;
          left: 9px;
          right: auto;
        }
        &>img {
          left:0;
          right:auto;
        }
      }
    }
    .chat-message__item__footer {
      flex-direction: row-reverse;
      align-self: flex-start;
      margin-left: 32px;
    }
  }
}