@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/button.scss';
@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/colours.scss';


.options-buttons {
  label {
    div.button {
      display: flex;
      margin-right:1em;
      margin-bottom:1em;
      @extend button, .btn;
      @extend button, .btn-secondary;
      border: 1px solid rgba(0,0,0,.1);
      background-color: #fff;
      text-transform: none;
      position: relative;
      border-radius: 8px;
      height: 70px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    
      .text {
        @extend .bold-type;
        @extend .color-text;
        letter-spacing: 0;
        display: block;
        text-align: center !important;
        line-height: 160%;
      }

      .text-mobile {
        @extend .semi-bold-type;
        font-size: 12px;
      }
    }
    
    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
    }

    input[type="checkbox"]:checked ~ div.button {
      @extend button, .btn-primary;
      border: 1px solid $color_java;
      .text {
        color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .options-buttons {
    div.button {
      position: relative;
      .tick-icon {
        position: absolute;
        top: 0.4em;
        left: 0.4em;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: $color_java;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
          width:8px;
          display: block;
        }
      }
      .text-mobile {
        text-align: center;
        letter-spacing: 0 !important;
      }
    }
    input[type="checkbox"]:checked ~ div.button {
      background-color: #fff !important;
      color: $color_nightsky !important;
      .tick-icon {
        visibility: visible;
      }
    }
  }
}