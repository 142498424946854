@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/variables.scss";

.page-header-wrapper {
  padding-top: 3%;
  left: 0;
  width: 100%;
  position: absolute;
  z-index:5;

  .page-header {
    width: 100%;
    max-width: 1434px;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    
    .home-logo {
      width: 275px;
      display:block;
      img {
        display:block;
        width: 100%;
      }
    }

    .nav {
      overflow: hidden;
      justify-content: center;
      
      .mobile-menu {  
        a {
          font-family: $fontFamilyBold;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          display: block;
          text-decoration: none;
          color:$color_nightsky;
          float: left;
          padding: 14px 32px;
          &:visited,
          :active,
          :link {
            color:$color_nightsky;
            padding-top: 10px;
          }
        }
      }
      .mobile-menu-button {
        display: none;
      }
    }
    .login-controls {
      display:flex;
      flex-wrap:nowrap;
      & > * {
        margin-left: 4px;
        margin-right: 4px;
        white-space: nowrap;
      }

      .btn-secondary {
        padding: 20px 45px;
      }
    }
  }

  .page-header-wrapper-bg {
    visibility: hidden;
  }
}

@media screen and (max-width: 1023px) {
  .page-header-wrapper {
    top: 0px;
    z-index: 99;
    position: fixed;
    display: flex;
    padding-top: 0;
    width: 100vw;
    background-color: rgba(255,255,255,0);
    transition-property: all;
    transition-delay: 0;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    overflow-y: scroll;
    
    .page-header {
      padding-left: 24px;
      padding-right: 18px;
      padding-top: 16px;
      display: flex !important;
      
      .home-logo {
        display: block;
        width: 210px;
      }
      
      .nav {
        display: block !important;
        width: 50px;
        .mobile-menu {
          display: none;
        }
        a.mobile-menu-button {
          display:block !important;
          width: 50px;
          img {
            display:block;
            width: 100%;
          }
        }
      }
      .login-controls {
        display: none;
      }
    }

    &.as-menu {
      height: 100vh;
      background-color: $color_nightsky;

      .page-header {
        align-items: start;
        justify-content:flex-start;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
        .nav {
          width: 100%;
          flex: 1 1 50%;
          padding-bottom:30px;
          padding-top:30px;
          flex: 0 0 330px;
          height: 100%;
          margin-top: 45px;
          .mobile-menu {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            a {
              font-family: $fontFamilySemiBold;
              color: $color_white;
            }
          }
          a.mobile-menu-button {
            margin-top:10px;
            width: 30px;
            position: absolute;
            top: 10px;
            right: 25px;
            
          }
        }
        .login-controls {
          display: flex;
          margin-top: auto;
          width:100%;
          padding-bottom: 45%;
          &>div {
            flex: 1 0 45%;
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .page-header-wrapper-bg {
    top: 0px;
    position: fixed;
    display: flex;
    height: 100px;
    width: 100vw;
    z-index: 98;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,0.7) 82%, rgba(255,255,255,0) 100%);
  }
}

@media screen and (min-width: 1920px) {
  .page-header-wrapper {
    .page-header {
      max-width: 1434px;
    }
  } 
}

@media screen and (max-width: 1600px) and (min-width: 1024px) {
  .page-header-wrapper {
    .page-header {
      max-width: 1120px;
      padding-left:4vw;
      padding-right:4vw;

      .home-logo {
        width: 200px;
      }
      
      .nav {
        a {
          padding: 1vw 1.2vw;
        }
        a:not(:first-child):not(:last-child) {
          padding: 1vw 1.2vw;
        }
        .mobile-menu {
          a {
            padding: 1vw 1.2vw;
          } 
        }
      }
    }
  }
}
