@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.ratings {
  display:flex;
  flex-direction: row;
  align-items: center;
  .stars {
    position: relative;
    img {
      width: 14px;
    }
    .fore {
      white-space: nowrap;
      position: absolute;
      overflow: hidden;
    }
    .back {
      white-space: nowrap;
      img {
        opacity: .3;
      }
    }
  }
  .rate {
    @extend .bold-type;
    margin-left:6px;
    color: $color_nightsky;
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    margin-top: 5px;
  }
  .count {
    @extend .base-typography;
    margin-left:6px;
    color: $color_nightsky;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    margin-top: 5px;
  }
}