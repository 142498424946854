@media screen and (max-width: 1023px) {
  #about {
    scroll-margin-top: 60px;
  }
  .about {
    h1 {
      font-size: 32px;
      line-height: 38.3px;
    }
    h6 {
      font-size: 10px !important;
    }
    .w-75 {
      width: 100% !important;
    }
  }
}