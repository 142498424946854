@import '../../../../assets/styles/variables.scss';
.service-cards-container {
  .service-card {
    border-radius: 8px;
    border:1px solid rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    margin: 1em;
    height: auto;

    .image {
      flex:0 0 50%;
      object-fit: cover;
      object-position: center center;
      img {
        display:block;
        width:100%;
        height:100%;
      }
    }

    .content {
      padding: 20px 32px;
      .body {
        padding:0;
      }
    }
  }
}

@media screen and (max-width:1023px) {
  .service-cards-container {
    margin-left:-15px;
    margin-right:-15px;
    .service-card {
      flex: 1 1 350px;
      height: auto !important;

      .content {
        padding: 20px 16px !important;
      }
    }
  }
}