@import '../../../../../../assets/styles/typography.scss';

.alerts-container {
  display:flex;
  flex-direction: row;
  align-items: center;
  min-width:0;
  .alerts {
    position: relative;
    .counter {
      @extend .bold-type;
      font-size: 10px;
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      display:flex;
      justify-content: center;
      align-content: center;
      
      background-color:rgb(241,41,72);
      color: #fff;
      top: 0;
      left: 100%;
      transform: translate(-60%, -45%)
    }
  }
}
