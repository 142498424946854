.bookmarks {
  padding-left:25px;
  padding-right:25px;
}

@media screen and (max-width:768px) {
  .bookmarks {
    img {
      height: 20px;
    }
  }
}