@use "../../assets/styles/variables" as vars;

@import "../../assets/styles/typography.scss";
@import "./resets.scss";

button {
  @extend .semi-bold-type;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

button.btn {
  border-radius: 100px;
  letter-spacing: 0.1em;
  line-height: 14px;
  font-size: 14px;
  color: vars.$color_nightsky;
  border: none;
  padding: 20px 32px;
  text-transform: uppercase;

  &.wide {
    padding-left: 67px;
    padding-right: 67px;
  }

  &.long {
    min-width:237px;
  }

  &.less-rounded {
    border-radius: 8px;
  }

  &.bordered {
    border: 1px solid rgba(0,0,0, 0.1);
  }
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  outline:none;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.03);
}

button.btn-primary {
  background-color: vars.$color_java;
  color: vars.$color_white;
  &:hover,
  &:focus {
    background-color: vars.$color_java;
    color: vars.$color_white;
  }
}
button.btn-secondary {
  @extend .bold-type;
  background-color: vars.$color_white;
  color: vars.$color_nightsky;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  &:hover,
  &:focus {
    background-color: vars.$color_white;
    color: vars.$color_nightsky;
  }
}
button.btn-primary-inverse {
  background-color: vars.$color_white;
  color: vars.$color_java;
  border:1px solid vars.$color_java; 
  &:hover,
  &:focus {
    background-color: vars.$color_white;
    color: vars.$color_java;
  }
}

button.btn-link {
  @extend .button-reset;
  color: vars.$color_azure;
  text-decoration: none;
  font-weight: 500;
}

button.btn-link-bordered {
  @extend .button-reset;
  color: vars.$color_azure;
  text-decoration: none;
  font-weight: 500;
  border-radius: 100px;
  border-width: 2px;
  border-style: solid;
  border-color: vars.$color_azure;

  &:hover {
    color: vars.$color_java;
    border-color: vars.$color_java;
  }
}

.btn-group {
  button.btn {
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);

    &.btn-primary {
      border: 1px solid vars.$color_java;
    }
  }
}