@import '../../../../../assets//styles/typography.scss';
@import '../../../../../assets//styles/variables.scss';

.icon-label {
  @extend .base-typography;
  color: $color_text;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  display:flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  margin-left:24px;
  margin-right:24px;
  white-space: nowrap;
  .icon {
    width: 18px;
    height:23px;
    margin-right: 8px;
    img {
      display:block;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      top:3px;
    } 
  }
}

@media screen and (max-width: 768px) {
  .icon-label {
    font-size: 8px;
    line-height: 8px;
    margin-left:8px;
    margin-right:8px;
    .icon {
      flex: 0 0 12px;
      width: 12px;
      height: auto;
      margin-top: 2px;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      object-fit: contain;
      img {
        width: 100%;
        height: 100%;
        top:-2px;
      }
    }
  }
}