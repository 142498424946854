.carousel-wrapper {
  position: relative;

  .carousel-left-button {
    position: absolute;
    top: 0;
    left: -30px;
    height: 100%;
    width: 40px;
    flex: 0 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      transform:rotate(180deg);
    }
  }
  
  .carousel-right-button {
    position: absolute;
    top: 0;
    right: -30px;
    height: 100%;
    width: 40px;
    flex: 0 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.mobile-business-needs {
  display:none;
}

.card-wrapper.corporate-dashboard-tech-req {
  width: 365px;
  
  .card.vertical {
    .card-body {
      .card-body-content {
        p {
          max-height: 39px;
          height: 39px;
        }
      }
    }
    .card-footer {
      padding: 16px 32px;
      justify-content: space-between !important;
      .icon-label {
        margin:0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .carousel-wrapper {
    display: none;
  }
  .mobile-business-needs {
    display: flex;
    flex-direction: column;
  }
  .card-wrapper.corporate-dashboard-tech-req {
    width: auto;
  }
}