@use '../../../../../../assets/styles/variables' as *;
@import '../../../../../../assets/styles/typography.scss';

.view-technology-request {
  display:flex;
  width: 100%;
  min-height: 980px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  .left {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    width: 320px;
    flex: 0 0 320px;
    background-color: $color_nightsky;
    color: #fff;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    .image {
      height: 216px;
      position: relative;
      width: 100%;
      border-top-left-radius: 24px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .top-right {
        position: absolute;
        top: 1em;
        right:1em; 
        z-index: 2;
      }
    }
    .recommended {
      position: absolute;
      z-index: 2;
      top:140px;
      left: 50%;
      transform: translateX(-50%);
      .body {
        @extend .base-typography;
        padding:33px;
        text-align: center;
        font-weight: 600;
        letter-spacing: -0.02em;
        font-size: 12px;
        color: $color_nightsky;
        position: absolute;
        width:100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          margin-bottom: 8px;
        }
      }
      .back {
        width:100%;
        img {
          display:block;
          width: 166px;
          height: 157px;
        }
      }
    }
    .key-info {
      padding: 100px 40px 120px 40px;
      position: relative;
      h6 {
        @extend .bold-type;
        color: #fff;
        margin:0;
        padding-bottom: 24px;
        padding-top:18px;
        letter-spacing: 0.02em;
      }
      .icon-label {
        margin-left:0;
        margin-right:0;
        align-items: flex-start;
        margin-bottom: 16px;
        &>span {
          @extend .base-typography;
          color: #fff;
          white-space: normal;
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          line-height: 160%;
          margin-top: -3px;
          text-transform: capitalize;
        }
      }

      .list {
        .item {
          display:flex;
          flex-direction: row;
          align-items: flex-start;
          margin-bottom: 16px;;
          &>label {
            margin-right: 16px;
            flex: 0 0 100px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
          }
          &>span {
            flex: 1 1 auto;
            @extend .medium-type;
            font-size: 14px;
          }
        }
      }
    }
  }
  .right {
    flex: 1 1 100%;
    padding: 72px 110px;
    background-color: #fff;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;

    .key-info {
      display: none;
    }

    p {
      font-size: 14px;
    }

    .start-conversation {
      display:flex;
      flex-direction: row;
      justify-content:center;
      align-items: center;
      padding: 24px 102px;
      .inner {
        display:flex;
        flex-direction: column;
        h6 {
          margin:0;
          margin-right: 24px;
        }
      }
      .code {
        p {
          line-height: 0px;
        }
      }
      
    }
  }
}

@media screen and (max-width:768px) {
  .view-technology-request {
    flex-direction: column;
    border-radius: 16px;
    margin-left: -12px;
    margin-right: -12px;
    width:auto;
    .left {
      width: 100%;
      flex: 0 0 114px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: transparent;
      .image {
        height: 144px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      .recommended {
        top: 100px;
        .body {
          font-family: $fontFamilyBold;
          font-size: 8px;
          line-height: 11.2px;
          img {
            width: 18px;
          }
        }
        .back {
          width: 100px;
          height: 95px;
        }
      }
      .key-info {
        display: none;
      }
    }
    .right {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 24px 24px 24px;
      h2 {
        font-size: 16px !important;
      }
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
      .link-unlock {
        width:auto !important;
        display: inline-flex;
        margin-left: 0 !important;
        padding-left: 0 !important;
        span {
          font-size: 12px;
          font-family: $fontFamilyRegular !important;
          font-weight: 400;
        }
      }
      .start-conversation {
        border:none !important;
        padding: 0;

        .app-btn {
          width: 100%;
        }

        h6 {
          margin-bottom: 24px;
        }
      }
      .key-info {
        display: block;
        padding: 32px 24px;
        margin-top:24px;
        margin-left: -24px;
        margin-right: -24px;
        background-color: $color_nightsky;
        

        h6 {
          color: #fff;
          padding-top:0;
          padding-bottom: 16px;
          font-weight: 700;
          margin: 0
        }

        .icon-label {
          margin-left:0;
          margin-bottom: 16px;
          align-items: flex-start;
          .icon {
            margin-right: 11px;
            img {
              width: auto;
              height: 16px;
            }
          }
          &>span {
            text-transform: capitalize;
          }
          &>span:last-child {
            flex: 1 1 auto;
            color: #fff;
            font-weight: 300;
            font-size: 12px;
            line-height: 140%;
            white-space: break-spaces;
          }
        }

        img.icon-work-corp {
          width: 15px;
          height: 16px;
          margin-right: 11px;
        }

        .list {
          .item {
            margin-bottom: 16px;
            color: #fff;
            display:flex;
            label {
              flex: 0 0 50%;
              font-weight: 300;
              font-size: 12px;
            }
            span {
              flex: 1 1 auto;
              font-size: 12px;
              font-weight: 700;
              line-height: 140%;
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}