@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.transfer {
  .bank-details {
    .field {
      @extend .txt-regular;
      font-size: 14px;
      line-height: 120%;
      color: $color_text;
      padding: 0;
    }
    .value {
      @extend .txt-semibold;
      font-size: 14px;
      line-height: 120%;
      color: $color_nightsky;
    }
  }
}