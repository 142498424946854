@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.latest-documents {
  display:flex;
  flex-direction: row;
  padding: 7px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .document {
    cursor: pointer;
    max-width: 45%;
    width: 100%;
    min-width:150px;
    min-height: 191px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    display:flex;
    flex-direction: column;
    padding: 32px 20px 0px 20px;
    margin: 7px;
    overflow: hidden;
  
    h5 {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      text-overflow: ellipsis;
    }
  
    .icon {
      width: 26px;
      margin-bottom: 16px;
    }
  
    .footer {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top:10px;
      padding-bottom: 10px;
      @extend .base-typography;
      display:flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content:left;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 11px;
      font-weight: 600;
      color: $color_text;
      background: rgba(230, 230, 230, 0.2);
      border-radius: 0px 0px 8px 8px;
  
      img {
        width: 12px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .document {
    max-width: 100%;
  }
}

@media screen and (max-width:768px) {
  .document {
    min-height: max-content;
    .icon {
      width: 19.2px;
    }
    h5 {
      font-size: 14px;
      margin-bottom:24px;
    }
    .footer {
      
      font-size: 8px;
      img {
        width: 10.8px;
      }
    }
  }
}