@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';


.tool-tip-info {
  position: relative;
  .tool-tip {
    position: absolute;
    z-index: 2;
    background-color: $color_grey_light2;
    padding:5px 10px;
    border-radius: 6px;
    color: $color_nightsky;
    left:100%;
    top: 100%;
    width: 100%;
    min-width: 260px;
    max-width: 700px;
    line-height: 140%;
    white-space: normal;
    transform: translate(-100%, 5px);
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .tool-tip {
      visibility: visible;
      transform: translate(-1em, -1em);
    }
  }
}

@media screen and (max-width:768px) {
  .tool-tip-info {
    width: 20px;
    .tool-tip {
      visibility: visible;
      display: none;
      min-width: auto;
      width: fit-content;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      &.active {
        visibility: visible;
        display: block;
      }
    }
  } 
}