.card-wrapper.conversation-card {
  cursor: pointer;
  flex: 0 0 382px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  .card {
    .card-body {
      .card-body-content {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom:20px;
        h5 {
          margin-bottom: 24px;
        }
        .status-checks {
          flex: 1 1 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .icon-label {
            margin-left:0;
            margin-right:0;
            font-size: 14px !important;
            text-transform: none;
          }
        }
      }
    }
  }
}

.conversation-other-company-logo {
  width: 120px;
  height: 120px;
  img {
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;
  }
}

.conversation-actions {
  display:flex;
  flex-direction: column;
  justify-items: center;
  justify-content:center;
  height: 100%;
  padding: 36px 36px 0 36px;
}