@import '../../../../assets/styles/resets.scss';
@import '../../../../assets/styles/variables.scss';

.input-password-control {
  display:flex;
  align-items: center;
  margin-top: 0;
  position: relative;
  input[type="text"],
  input[type="password"] {
    padding:17px 45px 17px 17px;
    width: 100%;
    border-radius: 8px;
    border-color: $color_grey;
    border-style: solid;
    border-width: 1px;
    &:focus {
      outline: none;
    }
    
    &:empty {
      background-color: #fff;
    }
  }
  input[type="password"]::-ms-reveal {
    display: none !important;
  }
  .toggle {
    position: absolute;
    right: 0;
    margin-right: 17px;
    @extend .button-reset;
    padding-left:8px;
  }
}

.input-password-control:has(input:focus) {
  border: 1px solid #000;;
}