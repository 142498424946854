@import '../../../../../../assets/styles/typography.scss';

.nav-item {
  height: 82px;
  width: 100%;
  padding-left: 18%;
  display:flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  img {
    width: 20px;
    height: 20px;
  }
  &>div {
    @extend .bold-type;
    color: rgba(23, 34, 77, 1);
    font-size: 18px;
    line-height: 100%;
    text-decoration: none;
    text-transform: none;
    margin-left:15px;
  } 

  
  .shadows {
    position: absolute;
    height:16px;
    width: 80%;
    bottom:-16px;
    margin:0;
    left:50%;
    transform: translateX(-50%);
    z-index: 2;

    span:nth-child(1) {
      display: block;
      width:40%;
      height: inherit;
      position: absolute;
      left:0;
      z-index: 2;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 1;
    }
    span:nth-child(2) {
      display: block;
      width: 100%;
      height: inherit;
      position: absolute;
      left:0;
      z-index: 1;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: .02;
    }
    span:nth-child(3) {
      display: block;
      width:40%;
      height: inherit;
      position: absolute;
      z-index: 2;
      right:0;
      background: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 1;
    }
  }

  &.active {
    position: relative;
    background-color: #17224D;
    z-index: 4;
    &:before {
      content: '';
      width: 8px;
      height: 100%;
      background-color: #17E99D;
      position: absolute;
      left: 0;
      top: 0;
    }
    div {
      color: #fff;
    }
  }

}

@media screen and  (max-width: 768px) {
  .nav-item {
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    .shadows {
      visibility: hidden;
    }
  }
}