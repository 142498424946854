@import '../../../../assets/styles/variables.scss';

.chat-component__body__messages {
  flex: 1 1 auto;
  height: 1px; // hack???
  padding-bottom: 20px;
  &>div {
    height: 100%;
    overflow-y: scroll;
    padding-right: 40px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
  
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: $color_grey_light4;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $color_text;
    }
  }
}