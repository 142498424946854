.services {
  background-image: url('../imgs/services-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  
  .action-row {
    width: 75%;
    .action-col {
      width: 100%;
      padding-left:0.5em;
      padding-right:0.5em;
      &.action-col-signup {
        width:60%;
      }
    }
  }

  .service-item-card {
    .copy {
      font-size: 14px;
    }
  }

  .btn-sign-up {
    width:auto;
  }
}


@media screen and (max-width: 1023px) {
  #services {
    scroll-margin-top: 40px;
  }
  .services {
    h1 {
      font-size: 32px;
    }
    h6 {
      font-size: 10px !important;
    }
    h5 {
      font-size: 14px;
    }
    .service-item-card {
      .box-white-rounded {
        padding: 32px 24px !important;
      }
      .title {
        font-size: 16px;
        margin-bottom: 16px !important;
      }
      .copy {
        font-size: 12px;
        line-height: 18px;
      }
      .service-icon {
        width: 64px;
        height: 64px;
        flex: 0 0 64px;
        img {
          width: 27px;
          height: 27px;
        }
      }
    }
    .action-row {
      width: 100%;
      .action-col {
        &.action-col-signup {
          width:100%;
        }
      }
    }
    .btn-sign-up {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
}