@import '../../../../../../assets/styles/typography.scss';
@import '../../../../../../assets/styles/resets.scss';

.button-back {
  @extend .button-reset;
  @extend .bold-type;
  font-weight: 700;
  font-family: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  display:flex;
  align-items: center;
  .icon {
    width: 42px;
    height: 42px;
    border: 1px solid #17E99D;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 16px;
    img {
      padding: 15px 12px;
    }
  }
  &:hover {
    .icon {
    }
  }
}