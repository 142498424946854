@use '../../assets/styles/variables.scss' as *;
@import '../../assets/styles/typography.scss';
@import '../../assets/styles/mixins.scss';

// disable react error overlay
body > iframe {
  display: none;
}

div.hr {
  @extend .bold-type;
  width:100%;
  clear:both;
  padding-top:20px;
  padding-bottom:20px;
  font-size: 14px;
  font-weight: 700;
  color: $color_nightsky;
  &.split-left-right {
    position: relative;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    &:before {
      content: '';
      flex: 1 1 40%;
      margin-right:5%;
      height: 1px;
      background-color: rgba(0,0,0, .1);
      justify-self: flex-start;
    }
    &:after {
      content: '';
      flex: 1 1 40%;
      margin-left:5%;
      height: 1px;
      background-color: rgba(0,0,0, .1);
      justify-self: flex-end;
    }
  }
}

@include p-x-list;
@include round-sizes;

.box-white-rounded {
  background: rgba(255,255,255, 0.7);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 40px 51px;
}

.box-white-rounded-shadow {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
}

.border-rounded-1 {
  border-radius: 8px;
  border:1px solid rgba(0,0,0, .1);
}

.elipse-8 {
  width: 8em;
  height: 8em;
  border-radius: 50%;
}

html {
  scroll-behavior: auto;
}

@media (min-width: 1920px) {
  .container {
    max-width: 1554px !important;
  }
}

@media (min-width: 768px) and (max-width:1024px) {
  .container {
    max-width: 100%;
  }
}