.card-wrapper.my-tech-solution-item {
  .card {
    .card-footer {
      .footer-info {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card-wrapper.my-tech-solution-item {
    .card {
      .card-image {
        height: 118px;
      }
      .card-body {
        .card-body-content {
          .app-btn {
            width: 100%;
          }
        }
      }
      .card-footer {
        .footer-info {
          display:flex;
          justify-content: space-between;
        }
      }
    }
  }
}