@use '../../../../../assets/styles/variables' as *;
@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/resets.scss';
@import '../../../../../assets/styles/forms.scss';

.change-password {
  position: relative;
  input[type="password"] {
    padding-right: 80px;
    width:100%;
  }
  button {
    position: absolute;
    right:16px;
    top:50%;
    transform: translateY(-50%);
    z-index: 2;
    @extend .button-reset;
    text-transform: uppercase;
    color: $color_azure;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
}