@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.sign-up-container {
  position: relative;
}
.sign-up-forms {
}

.title-get-started {
  color: $color_azure;
  text-transform: uppercase;
  font-family: $fontFamilyMedium;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
  top: -48px;
  margin-left:15%;
  width: 70%;
}