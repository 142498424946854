@import '../../../../../assets/styles/colours.scss';
@import '../../../../../assets/styles/variables.scss';

.my-subscription {
  .form-control {
    display: flex;
    flex-direction: row;
    label {
      flex: 0 0 23%;
      padding-top:2px;
      .text {
        text-transform: capitalize;
        
      }
    }
    .value {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 500;
      font-family: $fontFamilyMedium;

      a {
        font-weight: 500;
        font-family: $fontFamilyMedium;
      }

      b {
        font-weight: 700;
        font-family: $fontFamilySemiBold;
      }
    }
  }
}