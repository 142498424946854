@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.scope__team {
  display: flex;
  flex-direction: row;
  margin-left: -16px;
  margin-right: -16px;
  height: 374px;

  .team-item {
    width: 33.33333%;
    height: 374px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .member {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .photo {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    .input-image {
      background-color: transparent !important;
      width: 100%;
      margin-bottom: 14px;
      .preview {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 40px;
      }
      .input-area {
        .input-area-inner {
          margin-left: 55px;
          button.action {
            span {
              color: $color_azure;
            }
          }
        }
        .loading {
          left: 3%;
          top: 10%;
        }
      }
    }

    .field {
      width: 100%;
      margin-top:16px;
      input[type="text"] {
        font-family: $fontFamilyMedium;
      }
    }
  }
  
  button.add-team {
    border: 1px solid rgba(0,0,0, .1);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 54px;
      height: 54px;
    }
  }
}