@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/resets.scss";

.select-one {
  position: relative;
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 54px;
    .selected-wrapper {
      position: relative;
      flex: 1 1 100%;

      input {
        border:none;
        width: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        height: 100%;
        &:focus {
          opacity: 1;
        }
      }
    }
    .selected {
      font-weight: 500;
      font-family: $fontFamilyMedium;
      font-size: 14px;
      line-height: 100%;
      color: $color_nightsky;
      padding: 20px 0px 20px 20px;
      width: 100%;
      text-align: left;
    }
    .more {
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::selection {
        background-color: none;
      }
      img {
        flex: 0 0 10px;
        width: 10px;
      }
      &.expanded {
        img {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }
  }
  .options {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    z-index: 999;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    transform: translateY(-2px);
    .option-item {
      padding: 20px;
      &:hover,
      &.focused {
        background-color: $color_azure;
        color: #fff;
      }
    }

    &.hidden {
      visibility: hidden;
      .option-item {
        pointer-events: none;
      }
    }
  }
}

.input-group {
  flex-wrap: nowrap;
  input[type="text"] + .select-one {
    .input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.select-one.thicker {
  font-weight: 600;
  span.selected {
    font-weight: 600;
  }
}
.select-one.zero-border{
  .input{
    border:0px;
  }
  input[type="text"] {
    border: 0!important;
  }
}

@media (max-width: 768px) {
  .select-one.thicker {
    height: 49px !important;
  }
  .select-one{
    .input{
      height: 44px;
      height: 49px !important;
      .selected {
        font-size: 12px;
        padding: 16px;
      }
      .more {
        height: 44px;
        width: 44px;
      }
    }
    .options {
      .option-item {
        padding: 16px;
        font-size: 12px;
      }
    }
  }
}