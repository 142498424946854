@use 'variables' as var;

.rotate-180 {
  transform: rotate(180deg);
}

.w-100 {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.w-50 {
  width: 50%;
}
.w-30 {
  width: 30%;
}

.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

.align-items-normal {
  align-items: normal !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.alert-tangerine {
  background-color: #ff83620c;
  border-radius: 8px;

  p {
    font-size: 14px;
    color: #17224d;
  }
}

/* Positions */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* Margins */
/* Left Margin */
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}

/* Right Margin */
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}

@media (max-width: 500px) {
  /* left and right padding */
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  /* top and bottom padding */
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.ms-n-1 {
  margin-left: -1em;
}

.me-n-1 {
  margin-right: -1em;
}

.b-r-50 {
  border-radius: 50px;
}

.hover-border-azure {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var.$color_azure;
  }
}
.hover-border-tangerine {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var.$color_tangerine;
  }
}
.hover-border-purple {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var.$color_purple;
  }
}
.hover-border-java {
  border: 1px solid transparent;
  &:hover {
    border: 1px solid var.$color_java;
  }
}

.border-azure {
  border: 1px solid var.$color_azure;
}

.border-tangerine {
  border: 1px solid var.$color_tangerine;
}

.border-purple {
  border: 1px solid var.$color_purple;
}

.border-java {
  border: 1px solid var.$color_java;
}

.cursor-pointer {
  cursor: pointer;
}

.border-r-8 {
  border-radius: 8px;
}

.border-1 {
  border:1px solid rgba(0,0,0, .1);
}

.filter-grayscale-100-opacity-20 {
  filter:grayscale(100%) opacity(20%);
}

@media screen and (min-width:768px) {
  .wrap-2-md-column {
    flex-wrap: wrap;
    & > * {
      float: left;
      width: 45%;
      margin-right: 2%;
      margin-bottom: 16px;
    }
  }  
}