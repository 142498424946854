@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.dropdown {
  padding-left:25px;
  padding-right:25px;
  height: 100%;
  display: flex;
  &:before {
    content: '';
  }
  .dropdown-toggle {
    display: flex;
    flex-wrap: nowrap;
    &:after {
      border:none;
      margin:0;
      vertical-align: auto;
      content:none;
    }
    .dropdown-toggle-icon {
      margin-left:8px;
      flex: 0 0 15px;
      width: 15px;
      transition-property: all;
      transition-duration: .3s;
      transition-timing-function: ease;
      transform-origin: center center;
    }
  }
  .dropdown-menu {
    padding-top:0;
    padding-bottom:0;
    margin-top:10%;
    background-color:#fff;
    border-radius: 24px;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
    border: none;
    width: 270px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    pointer-events: none;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      content: '';
      display:block;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(45deg) translateX(-50%);
      transform-origin: center center;
      position: absolute;
      left:50%;
      z-index: -1;
    }
    .dropdown-item {
      cursor: pointer;
      width: 100%;
      padding: 0;
      padding-left: 40px;
      padding-right: 40px;
      .inner {
        width: 100%;
        padding: 22px 0;
        display:flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(108, 108, 108, 0.15);
        .dropdown-item-icon {
          margin-right: 16px;
          flex: 0 0 16px;
        }
        .dropdown-item-label {
          font-family: $fontFamilyMedium;
          min-width:100px;
          font-size: 14px;
          line-height: 100%;
          font-weight: 500;
          text-align: left;
          color: $color_nightsky;
        }
      }
      &:first-child {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
      &:last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        .inner {
          border-bottom: none;
        }
      }
    }
  }
  &:hover {
    .dropdown-menu {
      visibility: visible;
      pointer-events: all;
    }
  }
}

@media screen and (max-width: 768px) {
  .dropdown {
    .dropdown-menu {
      display:none;
    }
    &.active {
      .dropdown-toggle {
        .dropdown-toggle-icon {
          transform: rotate(180deg)
        }
      }
      .dropdown-menu {
        display: block;
        visibility: visible;
        pointer-events: all;
      }
    }
  }
}