@use "../../../../../assets/styles/variables" as *;
@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";

.app-btn {
  @extend .button-reset;
  @extend .bold-type;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  border-radius: 4px;
  color: $color_azure;
  border: 1px solid $color_azure;
  padding: 16px 25px;
  min-width: 150px;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.primary {
    background-color: $color_azure;
    color: #fff;
  }

  &.danger {
    background-color: $color_danger;
    border-color: $color_danger;
    color: #fff;
  }

  &.link {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &.icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > img {
      margin-right: 8px;
    }
  }

  &:disabled {
    background-color: $color_grey;
    color: $color_grey_dark;
    border: none;
    img {
      filter: brightness(0.1);
    }
  }

  &.tangerine {
    background-color: $color_tangerine;
    color: #fff;
    border-color: $color_tangerine;
  }

  &.tight {
    min-width: 0;
    padding: 16px 5px !important;
  }

  &.wide {
    padding-left: 67px;
    padding-right: 67px;
  }

  * {
    @extend .bold-type;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
  }
}
