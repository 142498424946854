.startup-services {
  .action-row {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .startup-services {
    .action-row {
      border: none;
      padding:0 !important;
      button.app-btn {
        flex: 1 1 50%;
        min-width: unset;
        padding-left:unset;
        padding-right:unset;
        &:first-child {
          margin-right:12px;
        }
        &:last-child {
          margin-left:12px;
        }
      }
    }
  } 
}