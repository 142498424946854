@import '../../../../../assets/styles/colours.scss';

.tech-request-not-published {
  position: absolute;
  z-index: 3;
  background-color: rgba(255,255,255, .7);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    color: $color_nightsky;
  }
}

@media screen and (max-width: 768px) {
  .tech-request-not-published {
    h3 {
      font-size: 14px;
      text-align: center;
    }
  }
}