@import '../../../../../../../assets/styles/typography.scss';
@import '../../../../../../../assets/styles/variables.scss';

.dropdown-messages {
  padding-left:25px;
  padding-right:25px;
  height: 100%;
  display: flex;
  &:before {
    content: '';
  }
  .dropdown-toggle {
    display: flex;
    flex-wrap: nowrap;
    &:after {
      border:none;
      margin:0;
      vertical-align: auto;
      content:none;
    }
    .dropdown-toggle-icon {
      margin-left:8px;
      flex: 0 0 15px;
      width: 15px;
    }
  }
  .dropdown-menu {
    padding-top:0;
    padding-bottom:0;
    margin-top:10%;
    background-color:#fff;
    border-radius: 24px;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1));
    border: none;
    width: 660px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    pointer-events: none;
    top: 65%;
    left: 50%;
    transform: translateX(-70%);
    &:before {
      content: '';
      display:block;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(45deg) translateX(-50%);
      transform-origin: center center;
      position: absolute;
      left:70%;
      z-index: -1;
    }
    .dropdown-header {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      .inner {  
        padding-top: 39px;
        padding-bottom: 32px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(108, 108, 108, 0.15);
      }
    }
    .dropdown-items-container {
      max-height: 70vh;
      overflow-y: auto;
    }
    .dropdown-item {
      cursor: pointer;
      width: 100%;
      padding: 0;
      padding-left: 40px;
      padding-right: 40px;
      .inner {
        width: 100%;
        padding: 32px 0 32px 0;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid rgba(108, 108, 108, 0.15);
        .content {
          display:flex;
          flex-direction: row;
          .from-user {
            margin-right: 8px;
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            overflow: hidden;
            object-fit: cover;
            object-position: center center;
            background-color: $color_nightsky;
            img {
              display:block;
              width: 100%;  
            }
          }
          .body {
            flex: 1 1 100%;
            .body-text {
              white-space: break-spaces;
            }
          }
        }
      }
      &:first-child {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
      &:last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        .inner {
          border-bottom: none;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
  &:hover {
    .dropdown-menu {
      visibility: visible;
      pointer-events: all;
    }
  }
}

@media screen and (max-width:768px) {
  .dropdown-messages {
    .dropdown-menu {
      display:none;
      width: 350px;
      top: 55px;
      transform: translateX(-71%);
    }
    &.active {
      .dropdown-menu {
        display: block;
        visibility: visible;
        pointer-events: all;
      }
    }
  }
}