@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/layout.scss';
@import '../../../../assets/styles/typography.scss';

.home-team {
  background-image: url('../imgs/team-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  h6 {
    font-size: 10px;
  }
  h1 {
    font-size: 48px;
  }
  h5 {
    font-size: 16px;
    &.join-team-cta {
      font-size: 16px;
    }
  }
  .profile-photo {
    position: relative;
    margin-bottom: 0px;
    &.has-link {
      cursor: pointer;
    }
  }
  .linkedin-icon {
    display: none;
  }

  .person-role {
    font-size: 12px;
  }
  .person {
    .offset-top-less {
      position: relative;
      top: -1.6em;
    }

    .photo-placeholder {
      @extend .elipse-8;
      opacity: .15;
      background-color: $color_text;
    }

    p {
      // @extend .light-type;
    }

    img.photo {
      filter: grayscale(1);
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-team {
    h1 {
      font-size: 32px;
    }
    h5 {
      font-size: 14px;
    }
    .person {
      .person-name {
        font-size: 14px;
      }
      .person-role {
        font-size: 10px;
      }
      .profile-photo {
        position: relative;
        margin-bottom: 10px;
        width: 97px;
        height: 97px;
        img {
          width: 100%;
          height: 100%;
  
          &.is-you {
            height: auto;
          }
        }
      }
      .offset-top-less {
        top: 0.6em;
      }
      .linkedin-icon {
        display:block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: -20px;
        z-index: 2;
        transform: translateX(-50%);
        
        img {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}