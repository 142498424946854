.tick-white {
    display: block;
    text-indent: -9999px;
    width: 16px;
    height: 13px;
    background: url(../../app/shared/assets/svgs/tick-white.svg);
    background-size: 16px 13px;
}

.nav-bars {
    display: block;
    text-indent: -9999px;
    width: 50px;
    height: 22px;
    background: url(../../app/shared/assets/svgs/nav-bars.svg);
    background-size: 50px 22px;
}

.nav-cross {
    display: block;
    text-indent: -9999px;
    width: 16px;
    height: 13px;
    background: url(../../app/shared/assets/svgs/nav-cross.svg);
    background-size: 16px 13px;
}

.arrow-right-green {
    display: block;
    text-indent: -9999px;
    width: 17.72px;
    height: 11px;
    background: url(../../app/shared/assets/svgs/arrow-right-green.svg);
    background-size: 17.72px 11px;
}

@media (max-width: 500px) {
    .tick-white {
        width: 14px;
        height: 10px;
        background-size: 14px 10px;
    }
}