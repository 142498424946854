@use "./variables" as var;

// base styles
.base-typography {
  font-family: var.$fontFamilyRegular;
  font-style: normal;
  font-weight: normal;
}

.bold-type,
strong,
b {
  font-family: var.$fontFamilyBold !important;
  font-weight: 700;
}

.semi-bold-type {
  font-family: var.$fontFamilySemiBold;
  font-weight: 600;
}

.medium-type {
  font-family: var.$fontFamilyMedium;
  font-weight: 500;
}

.light-type {
  font-family: var.$fontFamilyLight !important;
  font-weight: 300 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.text-regular {
  @extend .base-typography;
  font-weight: 400;
}

p, .txt-sm {
  @extend .light-type;
  font-size: 14px;
  color: var.$color_text;
}

a {
  @extend .base-typography;
  font-size: 16px;
  color: var.$color_nightsky;
  text-decoration: none;
  font-weight: bold;
}

.subtitle {
  @extend .base-typography;
  font-size: 14px;
  color: var.$color_tangerine;
  letter-spacing: 0.1em;
}

.headline {
  @extend .base-typography;
  font-size: 80px;
  line-height: 119.7%;
  letter-spacing: -0.02em;
  color: var.$color_nightsky;
  font-weight: bold;
}

.nav {
  @extend .base-typography;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h1 {
  @extend .bold-type;
  font-size: 48px;
  line-height: 57.46px;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: var.$color_nightsky;
}

h1.sm {
  @extend h1;
  font-size: 36px;
  line-height: 119%;
}

h2 {
  @extend .bold-type;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: var.$color_nightsky;
  letter-spacing: -0.02em;
}

h3 {
  @extend .bold-type;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: var.$color_nightsky;
}

h4 {
  @extend .bold-type;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: var.$color_nightsky;
}

h5 {
  @extend .bold-type;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 700;
  color: var.$color_nightsky;
}

h6 {
  @extend .medium-type;
  color: var.$color_nightsky;
  font-size: 14px;
  line-height: 100%;
}

h6.tangerine {
  @extend .medium-type;
  color: var.$color_tangerine;
  font-size: 14px;
  font-weight: 200;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h6.java {
  @extend .base-typography;
  color: var.$color_java;
  font-weight: 200;
  line-height: 100%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

b {
  font-family: var.$fontFamilyBold !important;
}

.line-height-100 {
  line-height: 100%;
}

.line-height-120 {
  line-height: 120%;
}

.line-height-140 {
  line-height: 140% !important;
}

.line-height-160 {
  line-height: 160% !important;
}

.txt-weight-500 {
  font-weight: 500 !important;
}

.txt-regular {
  @extend .base-typography;
  font-weight: 400 !important;
}

.txt-light {
  @extend .light-type;
  font-weight: 300 !important;
}

.txt-semibold {
  @extend .semi-bold-type;
  font-weight: 600 !important;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11-5 {
  font-size: 11.5px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.txt-lg {
  @extend .base-typography;
  font-weight: 300;
  font-size: 16px;
  line-height: 20.8px;
  color: var.$color_nightsky;
}

.txt-md {
  @extend .base-typography;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
}

.txt-uppercase {
  text-transform: uppercase;
}

.title-tangerine-s {
  color : var.$color_tangerine !important;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 500;
}

.text-normal {
  text-transform: none !important;
}
.description-text {
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */
  
  /* Text */
  color: var.$color_form_label;
}

.small-text-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var.$color_form_label;
}

.letter-spacing-0 {
  letter-spacing: 0;
}

.letter-spacing-n-2 {
  letter-spacing: -0.02em;
}

.letter-spacing-5 {
  letter-spacing: 0.05em;
}

.nowrap {
  white-space: nowrap;
}

/*
@media (max-width: 500px) {
  h1 {
    font-size: 32px !important;
  }
  h6 {
    font-family: $fontFamilyRegular !important;
    font-size: 10px !important;
    letter-spacing: 0.2em !important;
  }
}

@media (max-width: 1440px) {
  
}
*/

@media screen and (max-width: 1023px) {
  .font-size-md-10 {
    font-size: 10px !important;
  }
  .font-size-md-12 {
    font-size: 12px !important;
  }  
  .font-size-md-14 {
    font-size: 14px !important;
  }
}