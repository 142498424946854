.sign-up-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255,255,255, .5);

  .indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
		display:flex;
		flex-direction: column;
		align-items: center;

		h5 {
			color: #000;
			margin-top: 1rem;
		}
  }
}

.loader-inverse-1 {
	height: 32px;
	width: 32px;
	-webkit-animation: loader-inverse-1-1 4.8s linear infinite;
	        animation: loader-inverse-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-inverse-1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-inverse-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-inverse-1 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	-webkit-animation: loader-inverse-1-2 1.2s linear infinite;
	        animation: loader-inverse-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-inverse-1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-inverse-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader-inverse-1 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(0, 32px, 32px, 16px);
	border: 3px solid #000;
	border-radius: 50%;
	-webkit-animation: loader-inverse-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-inverse-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-inverse-1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-inverse-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}