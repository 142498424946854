@import '../../../../../assets/styles/variables.scss';

.service-card-startup {
  cursor: pointer;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .service-card-startup {
    padding:20px;
    .price-action {
      width: 100%;
      button.app-btn {
        width: 100%;
      }
    }
  }
}