@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.service__collaboration-left {

    .left__takingto-container {
        border: 1px solid $color_grey;
        border-radius: 8px;
        width: 280px;

        hr {
            height:1px;
            width: 100%;
            color: $color_text;
            padding: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .links {
            display:flex;
            flex-direction: column;
            justify-content: center;

            .link {
                @extend .button-reset;
                @extend .bold-type;
                text-align: center;
                padding: 12px 0px;
        
                img {
                    margin-right: 10px;
                }
            }
        }

        .catalyst {
            margin-top: 24px;
            margin-bottom: 24px;
            text-align: center;

            h6 {
                color: #6c6c6c;
                font-family: $fontFamilyRegular;
                font-weight: 400;
                line-height: 12px;
                font-size: 12px;
                margin: 8px 0px;
            }

            img {
                margin-top: -2%;
                object-fit: cover;
            }

            p {
                font-family: $fontFamilyMedium;
                color: $color_nightsky;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .left__chat-container {
        margin-top: 32px;
    }

    .left__catalyst-container {
        margin-top: 32px;
    }
}