@import '../../../../assets/styles/variables.scss';

.input-textarea-counter {
  width:100%;
  textarea {
    width:100%;
  }
  .counter {
    font-size: 12px;
  }
}

@media screen and (max-width:768px) {
  .input-textarea-counter {
    textarea {
      font-size: 12px !important;
      font-family: $fontFamilyRegular;
      font-weight: 400;
      padding: 16px;
      height:151px !important;
    }
    .counter {
      font-size: 10px;
    }
  }
}