$directioon: t, e, b, s;
$size_max: 10;

@mixin p-x-list {
  @for $i from 6 through $size_max {
    .p-#{$i} {
      padding: #{$i}em;
    }
    
    @each $d in $directioon {
      .p#{$d}-#{$i} {
        @if $d == t {
          padding-top: #{$i}em;
        }
        @if $d == e {
          padding-right: #{$i}em;
        }
        @if $d == b {
          padding-bottom: #{$i}em;
        }
        @if $d == s {
          padding-left: #{$i}em;
        }
      }
    }
  }
}

@mixin round-sizes {
  @for $i from 1 through $size_max {
    .round-#{$i} {
      border-radius: 50%;
      width: #{$i}em;
      height: #{$i}em;
    }
  }
}