@import '../../../../../assets/styles/variables.scss';

.sign-up-as {
  label.boxed-radio-input {
    flex: 0 0 48%;
    input[type="radio"] {
      position: absolute;
      z-index: 2;
      visibility: hidden;
      &:checked ~ div {
        background-color: $color_java;
        border-color: $color_java;
        color: #fff;
      }
    }
    >div {
      cursor: pointer;
      padding: 24px;
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(0,0,0, 0.1);
      box-sizing: border-box;
      border-radius: 8px;
      color: $color_nightsky;
    }
  }

  .options {
    width: 100%;
    .options-buttons-tick {
      margin-left: -16px;
      margin-right: -16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 772px;
      &>label {
        flex: 0 0 33.33333333%;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .sign-up-as {
    .options {
      padding-left:16px;
      padding-right:16px;
      .options-buttons-tick {
        width: auto;
        justify-content: space-between;
        flex-wrap: wrap;
        &>label {
          flex: 0 0 50%;
          width: 50%;
          padding-left:8px;
          padding-right:8px;
          div.button {
            padding: 10px 8px 10px 16px;
            margin-bottom:16px;
          }
        }
      }

      .options-buttons-tick.mobile-azure {
        label {
          div.button {
            .tick-icon {
              top:6px;
              left:6px;
              background-color: $color_azure;
            }
          }
    
          input[type="checkbox"]:checked ~ div.button {
            border: 1px solid $color_azure;
          }
        }
      }    
    }
  }
}