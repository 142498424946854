@import '../../../../../assets/styles/variables.scss';

.circle-checkbox {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border:1px solid #fff;
  background-color: $color_azure;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  img {
    display: block;
    width:12px !important;
  }
}