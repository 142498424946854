@import '../../../../../../assets/styles/typography.scss';
@import '../../../../../../assets/styles/variables.scss';

.greeting {
  @extend .bold-type;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $color_nightsky;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-left: 8px;
  }
}