@use '../../../../assets/styles/variables' as *;
@import '../../../../assets/styles/typography.scss';

#how-it-works {
  .process-step {
    h1.sm {
      @extend .bold-type;
      font-size: 32px;
      line-height: 100%;
      letter-spacing: -0.02em;
      font-weight: 700;
      color: $color_nightsky;
      height: 64px;
      display:flex;
      justify-content: start;
      align-items: center;
    }
  }

  .screen-computer {
    position: relative;
    width: 90%;
    max-width: 700px;
    .app-screen {
      position: absolute;
      top: 24px;
      left: 50%;
      display: block;
      width: 93%;
      transform: translate(-50%, 0);
    }
    .pc-screen {
      display: block;
      width :100%;
    }
  }
}

@media screen and (max-width: 1023px) {
  #how-it-works {
    h1 {
      font-size: 32px;
    }
    .process-step {
      h6 {
        font-size: 10px;
      }
      h1.sm {
        font-size: 18px !important;
        justify-content: center;
        line-height: 100%;
        height: auto;
      }
      .caret-right {
        padding:0;
        transform-origin: 50% 50%;
        transform: rotate(90deg);
      }
    }

    .screen-computer {
      width: 80%;
      .app-screen {
        top: 10px;
      }
    }
  }
}


@media screen and (min-width: 1024px) and (max-width: 1200px) {
  #how-it-works {
    .process-step {
      h1.sm {
        justify-content: center;
        line-height: 100%;
        height: auto;
      }
      .caret-right {
        padding:0;
        transform-origin: 50% 50%;
        transform: rotate(90deg);
      }
    }
  }
}