@use "../../../../assets/styles/variables" as *;
@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/resets.scss";


#conversation-detail {
    // final final final css edits
    .startDeliverables{
        margin-left: 5%;
        margin-top: 5%;
        min-height: 90px;
        textarea {
            @extend .base-typography;
            line-height: 150%;
        }

    }
    // final final css edits
    .longBorder{
        //corporate view
        border-right: 1px solid gray;
        border-color: #dbdbdb !important;
    }
    .leftLongBorder{
        //startup view
        border-left: 1px solid gray;
        border-color: #dbdbdb !important;
    }

    //final review alkelio edits
    .subtitle {
        font-size: 12px;
        line-height: 100%;
        font-weight: normal !important;
        font-family: $fontFamilyRegular;
        color: #6c6c6c !important;
    }
    .tagItem {
        font-family: $fontFamilyRegular;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #6c6c6c;
        margin: 8px 0px;
    }
    textarea.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline.MuiInput-inputMultiline {
        font-size: 14px !important;
    }
    //disabled text field style 
    textarea.MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled.MuiInputBase-inputMultiline.MuiInput-inputMultiline{
        border:0px;
        background-color: #0000;
        font-weight: 400;
        font-family: $fontFamilyRegular;
        padding:0px !important;
        min-height: 135px;
        color:#6c6c6c;
        line-height: 140%;
    }
    .sameLine {
        color: $color_azure;
        line-height: 100%;
        font-weight: 500 !important;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        text-decoration: none;
        bdo{
            font-family: $fontFamilyRegular;;
            color: $color_azure;
            line-height: 100%;
            font-weight: 500 !important;
            letter-spacing: 0.06em;
            text-transform: uppercase;
        }
    }
    // style fdor disabled input
    input[type="text"]:disabled.canBeDisabled {
        background-color: #d3d3d3;
    }

    //place holder to match disabled input background
    input[type="text"]:disabled::placeholder {
        background-color: #d3d3d3;
    }

    .pdfModal {
        height: 100vh;
        width: 100%;
        background-color: #252525;

        iframe {
            width: 100%;
            height: 90vh;
        }

        header {
            background: #252525;
            text-align: right;
        }

        button {
            color: white;
            background: transparent;
            float: right;
            font-size: 20px;
            padding-right: 28px;
            padding-top: 14px;
            padding-bottom: 14px;
            border: 0;
        }
    }

    
    #upDoc {
        font-family: $fontFamilyRegular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 100%;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #6c6c6c;
        margin-bottom: 8px;
    }

    .upfile {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        padding-top: 24px;
        padding-left: 24px;

        &:first-child {
            margin-top: 18px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            flex: 0 0 15px;
            width: 13px;
            margin-right: 8px;
        }

        a {
            flex: 1 1 auto;
            max-width: fit-content;
            width: auto;
            text-decoration: none;
        }

        .name {
            @extend .bold-type;
            color: $color_azure;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
        }

        button {
            margin-left: 8px;
            flex: 0 0 15px;
            align-items: center;
            display: flex;
        }
    }

    .mapfile {
        display: flex;
        align-items: center;
        margin-left: 32px;
        padding: 9px 0px;
        max-width: 250px;

        &:first-child {
            margin-top: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            flex: 0 0 15px;
            width: 13px;
            margin-right: 8px;
        }

        a {
            flex: 1 1 auto;
            max-width: fit-content;
            width: auto;
            text-decoration: none;
        }

        .name {
            @extend .bold-type;
            color: $color_azure;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
        }

        button {
            margin-left: 8px;
            flex: 0 0 15px;
            align-items: center;
            display: flex;
        }
    }

    .textPar {
        min-height: 99px;
        max-height: 100px;
    }

    .mapproposal {
        display: flex;
        align-items: center;
        padding: 9px 0px;

        &:first-child {
            margin-top: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            flex: 0 0 15px;
            width: 13px;
            margin-right: 8px;
        }

        a {
            flex: 1 1 auto;
            max-width: fit-content;
            width: auto;
            text-decoration: none;
        }

        .name {
            @extend .bold-type;
            color: $color_azure;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
            padding-top: 6px;
        }

        button {
            margin-left: 8px;
            flex: 0 0 15px;
            align-items: center;
            display: flex;
        }
    }

    .upname {
        @extend .bold-type;
        color: $color_azure;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
    }

    .subHeaderLabel {
        font-family: $fontFamilyMedium;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        color: #17224d;
    }

    .links {
        display: flex;
        flex-direction: column;
        color: $color_nightsky;
        font-size: 14px;
        font-family: $fontFamilyMedium;
        font-weight: 500;

        svg {
            color: $color_azure;
            margin-right: 1%;
        }
    }

    .inputMask {
        clear: both;
        margin-top: 0;
        color: #17224d;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        width: auto;

        // flex: 1 1 auto;
        // input {
        //     min-width: 50px;
        //     max-width: 110px;
        //     border: 1px solid #FFF;
        //     width: auto;
        //     padding: 17px 5px;
        //     border-radius: 8px;

        // }
    }

    .uploaded-files {
        display: none;
    }

    // button.action {
    //     @extend .button-reset;
    //     display:flex;
    //     align-items: left;
    //     img {
    //       flex: 0 0 1px;
    //       margin-right: 10px;
    //     }
    //     span {
    //       @extend .bold-type;
    //       font-style: normal;
    //       font-weight: bold;
    //       font-size: 14px;
    //       line-height: 100%;
    //       letter-spacing: 0.05em;
    //       text-transform: uppercase;
    //     }
    //   }
    .uploadDocuments {
        // display:flex;
        // align-items: left;
        font-family: $fontFamilyMedium;
        font-style: normal;
        font-size: 14px;
        line-height: 100%;
        color: $color_azure;
        margin-left: 32px;
        margin-bottom: 41px;
    }

    .uploadedDocs {
        .file {
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            &:first-child {
                margin-top: 18px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            img {
                flex: 0 0 15px;
                width: 13px;
                margin-right: 8px;
            }

            a {
                flex: 1 1 auto;
                max-width: fit-content;
                width: auto;
                text-decoration: none;
            }

            .name {
                @extend .bold-type;
                color: $color_azure;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 100%;
            }

            button {
                margin-left: 8px;
                flex: 0 0 15px;
                align-items: center;
                display: flex;
            }
        }
    }

    .link {
        @extend .button-reset;
        @extend .bold-type;
        text-align: center;
        // padding for book a meeting
        padding: 12px 0px;

        img {
            margin-right: 10px;
        }
    }

    .representative {
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;

        h6 {
            color: #5a5959;
            font-family: $fontFamilyRegular;
            font-size: small;
        }

        img {
            object-fit: cover;
            margin-top: -2%;
        }

        p {
            font-family: $fontFamilyBold !important;
            margin-bottom: -1%;
            margin-top: -1%;
            font-weight: 600;
            color: $color_nightsky;
            font-size: 14px;
        }

        span {
            color: $color_azure;
            font-weight: 300;
            font-size: 12px;
        }
    }

    .catalyst {
        margin-top: 24px;
        margin-bottom: 24px;
        text-align: center;

        h6 {
            color: #6c6c6c;
            font-family: $fontFamilyRegular;
            font-weight: 400;
            line-height: 12px;
            font-size: 12px;
            margin: 8px 0px;
        }

        img {
            margin-top: -2%;
            object-fit: cover;
        }

        p {
            font-family: $fontFamilyMedium;
            color: $color_nightsky;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .title {
        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-top: 3%;
            margin-bottom: 2%;
        }
    }

    .tabs {
        margin-top: 3%;
        // margin-left: 3%;
        max-width: 850px;

        button {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            height: 60px;
            border-radius: "5px";
            border: 1px solid #bdbdbd;

            .selected {
                border-color: $color_azure;

                svg {
                    color: $color_azure;
                }
            }
        }

        button:hover {
            border-color: $color_azure;
            background-color: white;
        }

        button:focus {
            border-color: $color_azure;

            svg {
                color: $color_azure;
            }
        }
    }

    .checkListIcon {
        color: $color_azure;
        font-size: large;
    }

    .mainBoard {
        margin-top: 5%;
        // background-color: rgb(248, 248, 248);
        background-color: #fcfcfc;

        label {
            display: flex !important;
        }
    }

    .uploadedContracts {
        margin-top: 5%;
        // background-color: rgb(248, 248, 248);
        background-color: #fcfcfc;
        min-height: 200px;
    }

    .buttonsBox {
        margin-top: 5%;
        flex-basis: 0;

        // margin-left: 2%;
        // margin-right: 2%;
        button {
            width: 100%;
            max-width: 202px !important;
            height: 46px !important;
            padding: 16px 24px;
            vertical-align: top;
        }
    }

    .orButton {
        max-width: 50px;
        // width: 50px;
        color: #6c6c6c;
        padding-left: 20px !important;
        text-align: center;

        b {
            font-size: 14px;
            font-family: $fontFamilyBold;
        }
    }
    .leftBox {
        border-color: rgb(224, 224, 224) !important;
    }
    .sideBySideBoard {
        margin-top: 5%;
        margin-right: 5%;
        // background-color: rgb(248, 248, 248);
        background-color: #fcfcfc;
    }

    .innerBoard {
        margin-top: 16px;
        margin-bottom: 5%;
        margin-right: 10%;
        background-color: white;
    }

    .questionTitle {
        display: block;
        text-align: left;
        margin-bottom: "0.35em";
    }

    .questionTitleCenter {
        display: block;
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 1.5rem;
    }

    .section {
        margin-top: 40px;
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 40px;

        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-bottom: 1%;
        }

        p {
            margin-bottom: 3%;
            margin-right: 10%;
        }

        svg {
            color: $color_azure;
            width: 8%;
            margin-right: 2%;
        }

        input {
            width: 90%;
            height: 100%;
            background-color: white;
            @extend .base-typography;
        }

        textarea {
            width: 100%;
            // margin-bottom: 3%;
            resize: none;
            @extend .base-typography;
        }

        // &.team {
        //     text-align: center;
        //     p{
        //         margin-right: 0px !important;
        //     }
        // }
    }

    .ChatButton {
        background-color: #17224d;
        color: white;
        margin-bottom: 32px;
        margin-top: 32px;
        width: 100%;
        //max width may be needed for fine tuning later
        // max-width: 190px !important;
        border: 1px black;
        text-align: center;
        // display: flex;
        // justify-content: space-between;
        // flex-direction: row;
        // padding-left: 5px!important;
        padding-right: 24px !important;
        border-radius: 8px;
    }

    .goalSelect {
        width: 90%;
    }

    .sectionStructure {
        margin-top: 3%;
        margin-left: 3%;

        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-bottom: 1%;
        }

        p {
            margin-bottom: 3%;
            margin-right: 10%;
        }

        svg {
            color: $color_azure;
            width: 8%;
            margin-right: 2%;
        }

        input {
            width: 100%;
            margin-bottom: 3%;
            margin-right: 3%;
            @extend .base-typography;
        }
    }

    .sectionProposal {
        min-height: 200px;
        margin-top: 3%;
        margin-left: 3%;
        margin-bottom: 3%;

        .subHeaderLabel {
            padding-top: 30px;
        }

        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-bottom: 1%;
        }

        p {
            margin-bottom: 3%;
            margin-right: 10%;
        }

        svg {
            color: $color_azure;
            width: 8%;
            margin-right: 2%;
        }
    }

    .darkHeader {
        font-size: 12px;
        line-height: 100%;
        font-weight: 500;
        color: #17224d;
        //made font bold againest figma design
        font-family: $fontFamilyBold;
        margin-bottom: 8px;
    }

    .buttons {
        // margin-left: 3%;
        // margin-right: 3%;
        //replace margin with padding
        padding: 0 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            height: 50px;
            margin-top: 10%;
            margin-bottom: 10%;
            width: 250px;
            color: $color_azure;
            font-family: $fontFamilyBold;
        }

        svg {
            margin-right: 5%;
            margin-left: 5%;
        }
    }

    .upload {
        display:flex;
        justify-content: flex-end;
        button {
            flex: 1 1 auto;
            background-color: $color_azure;
            color: white;
            max-width: 250px !important;
            width: 100%;
            text-align: center;
            // padding: 16px 24px;
        }

        button:hover {
            background-color: white;
            color: $color_azure;
        }
    }

    .upload-generate-contract-col {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .upload {
            padding-left: 1em;
            padding-right: 1em;
        }    
    }

    @media (min-width: 1550px) {
        .upload-generate-contract-col {
            display:flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
        }
    }

    .next {
        button {
            background-color: $color_azure;
            color: white;
        }

        button:hover {
            background-color: white;
            color: $color_azure;
        }

        label {
            width: 100%;
            padding-top: 3%;
            padding-bottom: 3%;
        }
    }

    .deleteButton {
        padding-right: 20px;

        button.remove {
            border: none;
            background: transparent;
            width: 40px;
            height: 40px;
            right: 10px;

            img {
                display: block;
                width: 100%;
            }
        }
    }
    #teamRole{
        color:#6c6c6c;
        font-family: $fontFamilyRegular;
        font-weight: 400;
        font-size: 12px;
    }
    .team {
        margin-top: 5%;
        margin-bottom: 5%;
        text-align: left;
        max-width: 255px;
        display: flex;
        flex-direction: column;
        
        p {
            font-family: $fontFamilyBold !important;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #17224d;
        }

        h6 {
            font-family: $fontFamilyRegular;
            font-size: 12px;
            line-height: 100%;
            color: #6c6c6c;
        }

        img {
            object-fit: cover;
        }

        span {
            color: $color_azure;
            font-weight: 300;
            font-size: 12px;
            line-height: 100%;
        }

        .input-image {
            background-color: transparent !important;
            .preview {
                width: 80px;
                height: 80px;
                overflow: hidden;
                border-radius: 40px;
            }
            .input-area {
                .input-area-inner {
                    margin-left: 80px;
                }
            }
        }
    }

    .timingRange {
        margin-top: 5%;
    }

    .timingSection {
        margin-top: 10%;
        margin-left: 10%;
        min-height: 266px;
        // background-color: #FCFCFC;

        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-bottom: 1%;
        }

        p {
            margin-bottom: 3%;
            margin-right: 10%;
            font-family: $fontFamilyMedium;
            font-size: 12px;
        }

        b {
            font-size: 14px;
            line-height: 100%;
            font-weight: 500;
            color: #17224d;
            font-family: $fontFamilyMedium;
        }

        svg {
            color: $color_azure;
            width: 8%;
            margin-right: 2%;
        }
    }

    .deliverables {
        margin-top: 10%;
        margin-left: 10%;
        min-height: 200px;
        // background-color: #FCFCFC;

        h6 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-bottom: 1%;
        }

        p {
            margin-bottom: 3%;
            margin-right: 10%;
        }

        svg {
            color: $color_azure;
            width: 8%;
            margin-right: 2%;
        }
    }

    .date {
        margin-bottom: 41px;
        margin-right: 32px;

        h6 {
            font-family: $fontFamilyRegular;
        }
    }

    .requirementSection {
        // min-height: 437px;

        // hr {
        //     border: 0;
        //     opacity: 0.1;
        //     border-left: 1px solid #000000;
        //     // transform: rotate(90deg);
        // }
        h4 {
            color: $color_nightsky;
            font-family: $fontFamilyBold;
            margin-top: 3%;
            margin-bottom: 3%;
        }

        p {
            margin-top: 5%;
            margin-bottom: 5%;
            margin-left: 5%;
            margin-right: 5%;
        }

        textarea {
            min-height: 100px;
            margin-left: 32px;
            padding-left: 5%;
            padding-top: 5%;
            padding-bottom: 5%;
            padding-right: 5%;
            margin-right: 32px;
            margin-bottom: 7%;
            background-color: white;
            border-radius: 5px;
            border: 1px solid rgb(214, 214, 214);
            line-height: 150%;
            @extend .base-typography;
        }
    }

    .plusSign {
        // margin-top: 10%;
        // margin-bottom: 10%;
        height: 360px;
        width: 290px;
        max-width: 100%;
        // commented to fix plus card height
        // margin-bottom: 40px;
    }

    .addTeam {
        button {
            height: 100%;
        }

        svg {
            padding-left: 5%;
            width: 40%;
            height: 50%;
        }
    }

    // .MuiBox-root-66 {
    //     border-style: dashed !important;
    // }

    .addMemberForm {
        margin-top: 10%;
        margin-bottom: 20%;
        width: 100%;

        h6 {
            color: #5a5959;
        }

        input {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            border: 1px solid rgb(214, 214, 214);
            @extend .base-typography;
        }

        label {
            margin-top: 0;
        }
    }

    .dropZone {
        margin-right: 10%;

        h6 {
            color: #5a5959;
        }
    }

    .agreementForm {
        margin-bottom: 1%;
        // margin-right: 3%;

        h6 {
            color: #5a5959;
        }

        input {
            width: 250px;
            height: 50px;
            border-radius: 5px;
            border: 1px solid rgb(214, 214, 214);
            @extend .base-typography;
        }

        label {
            margin-top: 0;
        }
    }

    .checkBox {
        margin-left: 32px;
        margin-right: 3%;
        margin-bottom: 3%;
        // padding-left: 14px;

        span {
            color: #5a5959;
        }

        input[type="checkbox"]:disabled {
            background-color: #d3d3d3;
        }
    }

    .Mui-selected {
        border-color: $color_azure !important;

        svg {
            color: $color_azure;
        }
    }

    .selected-item {
        float: left;
        margin-right: 8px;
        margin-bottom: 8px;
        background: #ffffff;
        border: 1px solid rgba(23, 34, 77, 0.1);
        box-sizing: border-box;
        border-radius: 32px;
        padding: 10px 10px 10px 12px;
        display: flex;
        max-width: 200px;
        align-items: center;
        justify-content: space-between;

        & > span {
            @extend .base-typography;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: $color_nightsky;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 1 70px;
        }

        button {
            @extend .button-reset;
            margin-left: 8px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            flex: 0 0 16px;
            background-color: $color_azure;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 8px;
                height: 8px;
                display: block;
            }
        }
    }

    .documents {
        display: flex;
        align-items: center;
        line-height: 100%;
        white-space: nowrap;
        margin:13px auto;
        &:first-child {
            margin-top: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            flex: 0 0 15px;
            width: 13px;
            margin-right: 8px;
        }

        a {
            padding-top: 8px;
            flex: 1 1 auto;
            max-width: fit-content;
            width: auto;
            text-decoration: none;
        }

        .name {
            @extend .bold-type;
            color: $color_azure;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 100%;
        }

        button {
            margin-left: 8px;
            flex: 0 0 15px;
            align-items: center;
            display: flex;
        }
    }

    .body {
        @extend .base-typography;
        font-size: 14px;
        line-height: 19.6px;
        display: flex;
        flex-direction: column;
        // padding-left: 24px;
        color: $color_text;

        h3 {
            margin: 0;
        }

        .content {
            margin-top: 16px;
            margin-left: 32px;
            //for mapped requirements
            padding-right: 43px;
            word-break: break-all;
        }

        .last {
            margin-bottom: 1em;
        }

        .link-extra {
            margin-top: auto;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            text-align: left;
            display: contents;
            color: $color_azure;
            margin: 0px 8px;
            border: 0;
            background: transparent;
        }
    }

    .boxTitle {
        & > span {
            @extend .bold-type;
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'Poppins-SemiBold';
            margin-left: 32px;
            font-weight: 600;
            color: $color_text;
            letter-spacing: 0.12em;
        }
    }
}

.chatModal {
    position: fixed;
    bottom: 0;
    left: 365px;
    // height: 600px;

    font-family: $fontFamilyRegular;

    // overflow-y: auto;
    // scroll-behavior: smooth;
    min-width: 600px;
    border-radius: 8px 8px 0px 0px;
    max-width: 790px;
    width: auto;
    background: #fff;

    header {
        background: #17224d;
        // text-align: right;
        height: 64px;
        margin-bottom: 6px;
        border-radius: 8px 8px 0px 0px;

        button {
            color: white;
            background: transparent;
            float: right;
            margin-top: 24px;
            text-align: center;
            border: 0;
        }
    }

    button {
        font-size: 14px;
        border-radius: 4px !important;
        min-width: 100px !important;
    }

    // &.chatBody {
    //     padding: 24px;
    //     max-width: 700px;
    //     max-height: 645px;
    // }

    &.rightChat {
        text-align: right;
        padding-right: 6px;
    }

    &.leftChat {
        text-align: left;
        padding-left: 6px;
    }
}

.chatBody {
    // padding-bottom: 24px;
    max-width: 710px;
    max-height: 645px;
    height: 340px;
    overflow-y: scroll;
    // overflow: scroll;
    scroll-behavior: smooth;
}

.chatWrapper {
    margin: 40px;
    margin-top: 0px;
    border: 1px solid #e6e6e6;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 8px;
}

.chatMsg {
    display: flex;
    align-items: flex-end;
    max-width: 75%;
}

.paperTabs {
    padding: 0px 40px;
    padding-top: 40px;

    .MuiTab-wrapper {
        font-family: $fontFamilyMedium !important;
        font-weight: 700 !important;
        font-size: 14px;
        padding: 8px;
    }

    // border-bottom:1px solid #E6E6E6;
    .MuiTabs-indicator {
        // to hide the red line under selection
        background: #fff;
    }

    .paperTab {
        background: rgba(243, 243, 243, 0.35) !important;
        border: 1px solid #e6e6e6 !important;
        color: #6c6c6c;
        border-bottom: 0px !important;
        box-sizing: border-box !important;
        border-radius: 8px 8px 0px 0px !important;
    }

    .Mui-selected {
        border-color: #e6e6e6 !important;
        color: #17224d !important;
        background: #ffffff !important;
    }

    &.selected {
        // to unify border color on selection
        border-color: #e6e6e6 !important;
        color: black !important;
        background: #ffffff !important;
    }
}

.sendButton {
    // float: right;
    color: white;
    background: #007fff;
    width: 120px;
    height: 46px;
    min-width: 100px !important;
    border-radius: 4px !important;
}

.bubble {
    background: #f4f4f4;
    border-radius: 28px;
    font-size: 14px;
    line-height: 1.4em;
    margin: 6px 12px;
    padding: 28px;
    position: relative;

    // tail
    &::before,
    &::after {
        content: "";
        bottom: 0;
        height: 20px;
        left: -9px;
        position: absolute;
    }

    &:before {
        border-color: #f4f4f4;
        border-left-style: solid;
        border-left-width: 20px;
        border-bottom-right-radius: 50%;
        z-index: -1;
    }

    &::after {
        background: #fff;
        border-bottom-right-radius: 50%;
        width: 10px;
        z-index: 1;
    }

    &.mine {
        background: #daecff;
        margin-left: auto;
        // text-align: right;

        &::before,
        &::after {
            left: auto;
            right: -9px;
        }

        &::before {
            border: none;
            border-color: #daecff;
            border-right-style: solid;
            border-right-width: 20px;
            border-bottom-left-radius: 50%;
        }

        &:after {
            border-bottom-left-radius: 50%;
        }
    }
}

.timestamp {
    font-weight: 300;
    font-size: 12px;
    line-height: 100%;
    margin: 5px 50px;
    color: #2d2d2d;

    &.mine {
        text-align: right;
    }
}

.chatImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0 5px;
    object-fit: cover;
    object-position: center center;
}

.chatFooter {
    // padding-left: 12px;
    padding: 6px;
    border: 1px solid #e6e6e6;
    margin: -12px;
    margin-top: 18px;
    border-radius: 8px;
}

.chatText {
    width: inherit;
    border: 1px solid #fff;
    border-right: 0;
    background: white;
    flex: 1 1;
    padding-left: 18px;
    padding-right: 18px;
    font-family: $fontFamilyRegular;
    color: $color_nightsky;
    font-size: 14px;
    line-height: 21px;
}

input.chatText:focus-visible {
    border-color: transparent;
    outline: none;
}

input.chatText::placeholder {
    font-family: $fontFamilyLight;
    color: $color_nightsky;
    font-size: 14px;
    line-height: 21px;
}

.Brange {
    color: #6c6c6c;

    font-family: $fontFamilyMedium;
    font-weight: 500;
    font-size: 12px;

    span {
        color: #17224d;
        font-size: 14px;
    }
}

.canBeDisabled {
    color: #17224d;
    min-width: 175px;
    width: auto;
}

//to increase input bar width
// input.canBeDisabled{
//     width: 100%;
// }
.chipill {
    float: left;
    box-sizing: border-box;
    display: flex;
    max-width: 200px;
    align-items: center;
    justify-content: space-between;
}
