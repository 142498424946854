.service-card-corporate {
  .price-action {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .service-card-corporate {
    .price-action {
      width: 100%;
    }
    button.app-btn {
      width: 100%;
      max-width: 100%;
    }
  }
}