.sign-in {
    h1 {
        font-size: 48px;
        text-align: left;
    }
    .description {
        text-align: left;
    }
}

.desc-text-lg {
    display: contents !important;
}

.desc-text-sm {
    display: none !important;
}

.finish-button-lg {
    display: inline-block !important;
}

.finish-button-sm {
    display: none !important;
}

@media (max-width: 500px) {
    .sign-in {
        h1 {
            font-size: 32px;
            text-align: center;
        }
        .description {
            text-align: center;
        }
    }
    .desc-text-lg {
        display: none !important;
    }

    .desc-text-sm {
        display: contents !important;
    }

    .finish-button-lg {
        display: none !important;
    }
    
    .finish-button-sm {
        display: inline-block !important;
    }
}
