@media screen and (max-width: 1023px) {
  #why-choose-us {
    scroll-margin-top: 60px;
  }
  #why-choose-us-section {
    .icon-circle-tick {
      width: 24px;
      height: 24px;
    }
    h1 {
      font-size: 32px;
      line-height: 38.3px;
    }
    h4 {
      font-size: 16px;
    }
    p {
      line-height: 19.6px;
    }
  }
}