@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.app-status-badge {
  font-family: $fontFamilyRegular;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-width: 127px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 100px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;

  &.size-large {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px;
    height: 46px;
    width: 159px;
  }

  &.size-fluid {
    width: 100%;
    white-space: normal;
  }

  &.options-border {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .content {
  }
  img {
    width: 14px;
    height: 14px;
    margin-left:8px;
    cursor: pointer;
  }
  .status {
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
    display:inline-block;
    border-radius: 7px;
    margin-right: 8px;
    &.success {
      background: $color_java;
    }
    &.in-progress {
      background: $color_tangerine;
    }
  }

  .tool-tip {
    position: absolute;
    z-index: 2;
    background-color: $color_grey_light2;
    padding:5px 10px;
    border-radius: 6px;
    color: $color_nightsky;
    left:100%;
    top:100%;
    width: 100%;
    min-width: 260px;
    max-width: 700px;
    line-height: 140%;
    white-space: normal;
    transform: translate(-1em, -1em);
    visibility: hidden;
  }
  &:hover {
    .tool-tip {
      visibility: visible;
    }
  }
}

@media screen and (max-width: 768px) {
  .app-status-badge {
    font-size: 10px;
    font-family: $fontFamilySemiBold;
    padding: 5px;
    min-width: unset;
    height: auto;
    line-height: 10px;

    img {
      width: 10px;
      height: 10px;
      margin-left:5px;
    }
    .status {
      flex: 0 0 10px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }

    .tool-tip {
      top: 25px;
      left: 5%;
      width: 90%;
      min-width: none;
      max-width: max-content;
    }
    

    &.size-large {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 13px 12px;
      height: 38px !important;
      font-size: 12px;
      font-family: $fontFamilyBold;
      font-weight: 700;
      width: 140px;

      img {
        width: 14px;
        height: 14px;
        margin-left:5px;
      }
      .status {
        flex: 0 0 12px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }

      .tool-tip-icon {
        
      }
    }
  
    &.size-fluid {
      width: 100%;
      white-space: normal;
    }
  }
}