@import '../../../../../assets/styles/resets.scss';

.app-header {
  height: 104px;
  background-color: rgba(255,255,255, 0.9);
  width:100%;
  padding-left: 0;
  padding-right: 0;
  display:flex;
  flex-direction: row;
  &:before {
    content: ' ';
    width: 18.22916666666667%;
    max-width: 350px;
    min-width: 280px;
    height: 100%;
    display: block;
  }
  .app-header-inner {
    width: auto;
    flex: 1 1 auto;
    display:flex;
    flex-direction: row;

    .left {
      padding: 35px 0 25px 10%;
      display:flex;
      width:auto;
      flex-direction: row;
      justify-items: center;
      align-items: center;
    }
  
    .right {
      width:auto;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 11%;
    }
  
    .header-nav-item {
      position: relative;
      @extend .button-reset;
      display:flex;
      align-items: center;
      justify-items: center;
      height: 100%;
      &:hover:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: #007FFF
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .app-header {
    .app-header-inner {
      .left {
        padding: 35px 0 25px 80px;
      }
      .right {
        padding-right: 8%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .app-header {
    .app-header-inner {
      .left {
        padding: 35px 0 25px 70px;
      }
      .right {
        padding-right: 6%;
      }
    }
  }
}


@media (max-width: 1024px) {
  .app-header {
    .app-header-inner {
      .left {
        padding: 35px 0 25px 40px;
      }
      .right {
        padding-right: 6%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-header {
    padding: 0;
    height: 80px;
    position: fixed;
    z-index: 20;
    &:before {
      display: none;
    }

    .app-header-inner {
      .left {
        display: none;
      }
  
      .right {
        margin-left: 0;
        margin-right: 0; 
        padding-right: 0;
        display:flex;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid rgba(240, 240, 240, 1);
        border-bottom: 1px solid rgba(240, 240, 240, 1);
        .header-nav-item {
          flex: 1 1 auto;
          display:flex;
          align-items: center;
          justify-content: center;
          &:nth-child(n) {
            border-right: 1px solid rgba(240, 240, 240, 1);
          }
          &:hover:before {
            display: none;
          }
        }
      }
  
      .logo {
        margin-left: 0;
        width: 100%;
        height: auto;
        object-fit: contain;
        img {
          display:block;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .app-header {
    padding: 0;
    height: 80px;
    position: fixed;
    z-index: 20;

    .left {
      display: none;
    }

    .right {
      margin-left: 0;
      margin-right: 0; 
      display:flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid rgba(240, 240, 240, 1);
      border-bottom: 1px solid rgba(240, 240, 240, 1);
      .header-nav-item {
        flex: 1 1 auto;
        display:flex;
        align-items: center;
        justify-content: center;
        &:nth-child(n) {
          border-right: 1px solid rgba(240, 240, 240, 1);
        }
        &:hover:before {
          display: none;
        }
      }
    }

    .logo {
      margin-left: 0;
      width: 100%;
      height: auto;
      object-fit: contain;
      img {
        display:block;
        width: 100%;
      }
    }
  }
}