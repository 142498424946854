.matches-tech-solution-card-image-overlay {
  width:  100%;
  height: 100%;
  position: relative;
  .completion-container {
    position: absolute;
    top: 1em;
    left: 1em;
  }
  .match-status-container {
    position: absolute;
    width: 88px;
    height: 88px;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%,-50%);
  }
}

.card-wrapper.startup-tech-solution-matches-card {
  .card {
    .card-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
    }
  }
  .card.horizontal {
    .card-image {
      border-top-left-radius: 8px;
      border-top-right-radius: 0;
      flex: 0 0 360px;
    }
    .card-body {
      .card-body-content {
        padding-top: 40px;
        p {
          font-size: 14px;
          overflow: hidden;
          max-height: 65px;
        }
        .tags {
          max-height: 78px;
          overflow: hidden;
        }
      }
      .card-footer {
        justify-content: start;
        padding-left: 9%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .card-wrapper.startup-tech-solution-matches-card {
    .card.horizontal {
      .card-image {
        flex: 1 1 360px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .card-wrapper.startup-tech-solution-matches-card {
    width: 279px !important;
    .card.horizontal {
      flex-direction: column;
      .card-image {
        height: 144px;
      }
      .card-body {
        .card-body-content {
          padding-top: 20px;
          h5 {
            margin-bottom: 12px;
          }
          p {
            font-size: 12px;
          }
          .tags {
            max-height: 78px;
          }
        }
      }
    }
  }

  .matches-tech-solution-card-image-overlay {
    .completion-container {
    }
    .match-status-container {
      width: 44px;
      height: 44px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card-wrapper.startup-tech-solution-matches-card {
    .card.horizontal {
      height: 448px;
      .card-image {
        height: 144px;
      }
      .card-body {
        .card-body-content {
          .card-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 8px;
          }
          p {
            max-height: unset;
            height: 68px;
            overflow: hidden;
            padding: 0;
            margin-bottom: 20px;
          }
          .actions {
            button {
              height: 44px;
            }
            button:first-child {
              margin-top: 0 !important;
            }
          }
          .tags {
            display: none !important
          }
        }
        .card-footer {
          height: 34px;
          padding-left: 16px;
          padding-right: 16px;
          justify-content: space-between;
        }
      }
    }
  }
}