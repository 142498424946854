@import '../../../../../assets/styles/variables.scss';

.logo {
  margin-left: 18.22916666666667%;
  height: 122px;
  display:flex;
  align-items: center;
  position: relative;

  .beta {
    font-family: $fontFamilyBold;
    color: #FF8362;
    font-weight: 700;
    position: absolute;
    bottom: 22px;
    left: 0;
    width: 198px;
    text-align: right;

    .text {
      text-align: right;
    }
  }
}