@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.card-wrapper {
  width: 382px;
  height: 422px;
  .card {
    display:flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    height: inherit;
    .card-image {
      position: relative;
      background-color: #666;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .overlay {
        position: absolute;
        z-index:2;
        &.top-left {
          top: 20px;
          left: 20px;
        }
        &.top-right {
          top: 20px;
          right: 20px;
        }
        &.center {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &.none {
          width: 100%;
          height: 100%;
        }
      }
      img {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 0;
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
        &[src=""] {
          visibility: hidden;
        }
      }

      &.filter-darken {
        background-color: $color_nightsky;
        &>img {
          opacity:0.5
        }
      }
    }
    .card-body {
      padding:0;
      display:flex;
      flex-direction: column;
      flex:1 1 auto;
      position: relative;

      .action-overlay {
        position: absolute;
        width: 100%;
        height: calc(100% - 35px);
        z-index: 2;
      }
      .card-body-content {
        font-family: $fontFamilyRegular;
        white-space: normal;
        padding: 80px 80px 30px 80px;
        overflow-y: auto;
        height: 100%;
        .card-title {
          font-family: $fontFamilyBold;
          font-size: 20px;
          line-height: 20px;
          color: $color_nightsky;
          margin-bottom: 24px;
        }
      }
      .card-footer {
        padding:0;
        flex-basis: 45px;
        border-top: 1px solid transparent;
      }
    }

    &.horizontal {
      flex-direction: row;
      width: 100%;
      
      .card-image {
        flex: 0 0 50%;
        img {
          height: 100%;
        }
      }
      .card-body {
        .card-body-content {
          flex: 1;
        }
        .card-footer {
          display:flex;
          flex-basis: 45px;
          flex-direction: row;
          justify-content: center;
        }
      }
    }

    &.vertical {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      .card-image {
        height: 159px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        img {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 0;
        }
      }
      .card-body {
        .card-body-content {
          padding: 32px 32px 0 32px;
          h5 {
            font-size: 16px;
            line-height: 100%;
            margin-bottom: 0;
          }
          p {
            margin-top: 16px;
            margin-bottom: 24px;
            font-size: 14px;
            max-height: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .card-footer {
          display:flex;
          flex-direction: row;
          flex-basis: 45px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.action-overlay-active {
      .card-image {
        filter:blur(10px) opacity(0.3);
      }
      .card-body {
        .card-body-content {
          filter:blur(10px) opacity(0.3);
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .card-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    .card {
      .card-image {
        .overlay {
          &.top-left {
            top: 16px;
            left: 16px;
          }
          &.top-right {
            top: 16px;
            right: 16px;
          }
        }
      }
      .card-body {
        .card-body-content {
          padding: 24px !important;
          .card-title {
            font-size: 14px;
            font-family: $fontFamilyBold;
          }
          h5 {
            font-size: 14px;
          }
          p {
            font-size: 12px;
          }
        }
        .card-footer {
          padding: 13px 8px;
          flex-basis: auto !important;
        }
      }

      &.horizontal {
        flex-direction: column;

        .card-image {
          flex: 0 0 118px;
          img {

          }
        }
      }
    }
  }
}