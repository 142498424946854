.sign-up-inline {
  .btn-group {
    button.btn {
      height: 70px;
      text-transform: none;
    }
  }

  .options-buttons {
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-left:-0.5em;
    margin-right:-0.5em;
    label {
      flex:1 1 47%;
      display:flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      padding:0.5em;
      
      div.button {
        width:100%;
        margin:0;
      }
      &:nth-child(even) {
        justify-content: end;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .sign-up-inline {
    h6 {
      font-size: 10px !important;
    }
    h1 {
      font-size: 32px !important;
    }
    .btn-signup {
      width: 100%;
    }
    button.btn {
      font-size: 12px;
    }
    .options-buttons {
      &>label {
        flex: 0 0 50%;
        &>.button {
          padding-left: 16px;
          padding-right: 16px;
          padding-top:5px;
          padding-bottom: 5px;
          display:flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}