.button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

.input-reset {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  &:focus {
    border: none;
    outline: none;
  }
  &:focus-visible {
    border: none;
    outline: none;
  }
}