@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';

.input-checkbox {
  min-width: 0;
  max-width: auto;
  &>label {
    font-family: $fontFamilyRegular;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: left;
    cursor: pointer;
    .darkColor{
      color: #17224D
    }
    .checkbox {
      background-color: #FFFFFF;
      border: 1px solid #E6E6E6;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 8px;
      display:flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      img {
        display: block;
        width: 10px;
        height: 10px;
        visibility: hidden;
      }
    }
    &>input[type="checkbox"] {
      opacity: 0;
      width:0;
    }
    
    &>input[type="checkbox"]:checked + .checkbox {
      background-color: $color_azure;
      border: 1px transparent;
      img {
        visibility: visible;
      }
    }
  }

  &.box-java {
    &>label {
      &>input[type="checkbox"]:checked + .checkbox {
        background-color: $color_java;
      }
    }
  }

  &.box-text {
    &>label {
      &>input[type="checkbox"]:checked + .checkbox {
        background-color: $color_text;
      }
    }
  }
}

@media screen and (max-width:768px) {
  .input-checkbox {
    &>label {
      font-family: $fontFamilyMedium;
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}