@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.pill {
  margin-right: 8px;
  margin-bottom: 8px;
  background: #FFFFFF;
  border: 1px solid rgba(23, 34, 77, 0.1);
  box-sizing: border-box;
  border-radius: 32px;
  padding: 8px 12px 8px 10px;
  display: flex;
  max-width: 200px;
  align-items: center;
  justify-content: space-between;
  .icon {
    margin-right:8px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    flex: 0 0 16px;
    background-color: $color_azure;
    display:flex;
    align-items: center;
    justify-content: center;
    img {
      width: 8px;
      height: 8px;
      display:block;
    }
  }

  .label {
    @extend .base-typography;
    margin-right: 8px !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: $color_nightsky;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex:1 1 100%;
    height: 14px;
  }

  &.inverse {
    background-color: transparent;
    border:1px solid rgba(255, 255, 255, .2);
    .icon {
      background-color: $color_java;
      border:1px solid rgba(255, 255, 255, .2);
    }

    &>span {
      color: #fff;
    }
  }
  
  &.show-all {
    max-width:max-content;
    .label {
      white-space: normal;
      overflow: visible;
      display:block;
      height: auto;
    }
  }

  .tool-tip {
    display:none; 
  }
}

@media screen and (max-width:768px) {
  .pill {
    border-radius: 16px; 
    padding: 8px;
    .icon {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      flex: 0 0 12px;
      img {
        width: 5px;
        height: 5px;
        display:block;
      }
    }
    .label {
      font-size: 11px;
      height: 11px;
      text-transform: uppercase;
    }
  }
  
}