@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/colours.scss';

.chat-component {
  display: flex;
  position: fixed;
  z-index: 10;
  bottom: 0px;
  .chat-component__wrapper {
    border-radius: 8px;
    width: 791px;
    height: 80vh;
    max-height: 850px;
    overflow: hidden;
    background-color: $color_white;
    box-shadow: 8px 8px 100px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .chat-component__header {
      background-color: $color_nightsky;
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 24px;
      span {
        flex: 1 1 auto;
        color: $color_white;
        font-family: $fontFamilyBold;
        font-size: 14px;
        padding-left: 8px;
      }
      &>img:last-child {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .chat-component__body {
      padding: 24px;
      display:flex;
      flex-direction: column;
      height: 100%;
      .chat-component__body__conversation {
        display:flex;
        flex-direction: column;
        border-radius: 8px;
        border:1px solid $color_grey;
        padding: 24px;
        height: 100%;
      }
    }
  }
}