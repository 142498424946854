@import '../../../../../assets/styles/resets.scss';

.button-favourite {
  @extend .button-reset;
  border:none;
  background: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display:flex;
  justify-content: center;
  align-items: center;

  .icon {
    position: relative;
    width: 18px;
    height: 18px;
    &>img {
      top:2px;
      position: absolute;
      display:block;
      width: 18px;
      height: auto !important;
      object-fit: initial;
      object-position: initial;
      &.default {
        visibility: visible;
      }
      &.selected { 
        visibility: hidden;
      }
      &.rejected { 
        visibility: hidden;
      }
    }
  }

  &:hover,
  &.selected {
    background: rgba(255,255,255,1);
    .icon {
      &>img {
        &.default {
          visibility: hidden;
        }
        &.selected {
          visibility: visible;
        }
        &.rejected { 
          visibility: hidden;
        }
      }
    }
  }

  &.mode-reject {
    &:hover {
      .icon {
        &>img {
          &.default {
            visibility: hidden;
          }
          &.selected {
            visibility: hidden;
          }
          &.rejected { 
            visibility: visible;
          }
        }
      }
    }

    &.selected {
      background: rgba(255,255,255,1);
      .icon {
        &>img {
          &.default {
            visibility: hidden;
          }
          &.selected {
            visibility: hidden;
          }
          &.rejected { 
            visibility: visible;
          }
        }
      }
    }
  }

}

@media screen and (max-width:768px) {
  .button-favourite {
    &:hover {
      background: rgba(255, 255, 255, 0.2);

      .icon {
        &>img {
          &.default {
            visibility: visible;
          }
          &.selected {
            visibility: hidden;
          }
          &.rejected { 
            visibility: hidden;
          }
        }
      }
    }
  }
}