@use '../../../../assets/styles/variables' as var;
@import '../../../../assets/styles/button.scss';
@import '../../../../assets/styles/typography.scss';
@import '../../../../assets/styles/colours.scss';

.options-buttons-tick {
  label {
    div.button {
      display: flex;
      margin-bottom:32px;
      border: 1px solid rgba(0,0,0,.1);
      background-color: #fff;
      text-transform: none;
      position: relative;
      border-radius: 8px;
      height: 92px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding: 24px 36px;
      width: 100%;

      &:nth-child(n+3) {
        margin-bottom: 0;
      }

      .tick-icon {
        position: absolute;
        top: 12px;
        left: 12px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: var.$color_java;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-content: center;
        img {
          width:8px;
          display: block;
        }
      }
      .text-mobile {
        text-align: center;
      }
    
      .text {
        @extend .bold-type;
        @extend .color-text;
        letter-spacing: 0;
        display: block;
        text-align: center !important;
        line-height: 160%;
      }

    }
    
    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
    }

    input[type="checkbox"]:checked ~ div.button {
      background-color: #fff !important;
      border: 1px solid var.$color_java;
      .tick-icon {
        visibility: visible;
      }
    }
  }
}
