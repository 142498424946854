.tech-request-form-grid {
  display: grid;
  grid-template:
    [title-start] "title title title" auto [title-end]
    [action-top-start] "action-top action-top action-top" auto [action-top-end]
    [row-1-start] "photo tech-background tech-background" auto [row-1-end]
    [row-2-start] "options tech-background tech-background" auto [row-2-end]
    [row-3-start] "options need-help need-help" auto [row-3-end]
    [row-4-start] "tech-type solution-info solution-info" auto [row-4-end]
    [row-5-start] "industries solution-info solution-info" auto [row-5-end]
    [row-6-start] "business-use-case solution-info solution-info" auto [row-6-end]
    [row-7-start] "project-budget project-budget project-timing" auto [row-7-end]
    [row-8-start] "project-budget project-budget countries" auto [row-8-end]
    [action-bottom-start] "action-bottom action-bottom action-bottom" auto [action-bottom-end]
    / 382px 107px auto;
  gap: 32px;

  .field-title {
    grid-area: title;
  }
  .action-top {
    grid-area: action-top;
  }
  .action-bottom {
    grid-area: action-bottom;
  }
  .field-image {
    grid-area: photo;
    height: 388px;
    .input-image {
      height: 388px;
      .input-area {
        height: 388px;
      }
    }
  }
  .field-options {
    grid-area: options;
  }
  .field-need-help {
    grid-area: need-help;
  }
  .field-tech-background {
    grid-area: tech-background;
    align-self: start;
    textarea {
      height: 81px;
    }
  }
  .field-solution-info {
    grid-area: solution-info;
  }
  .field-tech-type {
    grid-area: tech-type;
  }
  .field-industries {
    grid-area: industries;
  }
  .field-business-use-case {
    grid-area: business-use-case;
  }
  .field-project-budget {
    grid-area: project-budget;
  }
  .field-project-timing {
    grid-area: project-timing;
  }
  .field-countries {
    grid-area: countries;
  }
  .grid-impact-horizon-goals {
    display: grid;
    grid-template: 
      [row1-start] "impact goals" auto [row1-end]
      [row2-start] "horizon goals" auto [row2-end]
      / 45% auto;
    gap: 38px;

    .field-impact {
      grid-area: impact;
    }
    .field-horizon {
      grid-area: horizon;
    }
    .field-goals {
      grid-area: goals
    }
  }
}

@media screen and (max-width:768px) {
  .action-row-top {
    .actions {
      display:none !important;
    }
  }
  .tech-request-form-grid {
    display:flex;
    flex-direction: column;
    gap:24px;
    
    .field-image {
      height: 284px;
      .input-image {
        height: 284px;
        .input-area {
          height: 284px;
        }
      }
    }

    &>div {
      width: 100%;
    }
    .grid-impact-horizon-goals {
      display:flex;
      flex-direction: column;
      gap:24px;
    }

    .action-bottom {
      .actions {
        width: 100% !important;
        margin-left:0 !important;
        button {
          width: 100%;
        }
      }
    }
  }
}