@import "../../../../../assets/styles/typography.scss";
@import "../../../../../assets/styles/resets.scss";
@import "../../../../../assets/styles/variables.scss";

.scope__info {
  .button-add-info {
    img {
      width: 16px;
      height: 16px;
    } 
  }
  .question-response {
    width: 100%;
    flex:1 1 100%;
    margin-bottom:16px;
  }
  
  button.remove {
    width:auto;
    margin-left: 8px;
    min-width: 0;
    align-self: flex-start;
    img {
      width:20px;
    }
  }
}