@import "../../../../assets/styles/typography.scss";
@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/resets.scss";

.select-multiple {
  position: relative;
  .input {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    height: 54px;
    justify-content: space-between;

    .selected-options {
      flex: 1 1 100%;
      margin-left: 16px;
    }
    
    input[type="text"] {
      @extend .input-reset;
      font-family: $fontFamilyRegular;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: $color_nightsky;
      width: 100%;
      height: 54px;
      padding: 1px 1px 1px 16px;
      border: none !important;
      flex: 1 1 auto;
      
      &::placeholder {
        color: $color_nightsky;
        opacity:1;
      }

      &.overlaid {
        position: absolute;
        z-index: 2;
      }
    }
    .more {
      padding: 16px;
      display:flex;
      cursor: pointer;
      &::selection {
        background-color: none;
      }
      img {
        flex: 0 0 10px;
        width: 10px;
      }
      &.expanded {
        img {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }

      &.overlaid {
        position: absolute;
        z-index: 3;
      }
    }
  }

  .selected-options {
    margin-top: 16px;
  }

  .options {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    z-index: 999;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    transform: translateY(-6px);
    top: 55px;
    .option-item {
      padding: 20px;
      &:hover,
      &.focused {
        background-color: $color_azure;
        color: #fff;
      }
    }

    &.hidden {
      visibility: hidden;
      .option-item {
        pointer-events: none;
      }
    }
  }

  .selected-item {
    float: left;
    margin-right: 8px;
    margin-bottom: 8px;
    background: #ffffff;
    border: 1px solid rgba(23, 34, 77, 0.1);
    box-sizing: border-box;
    border-radius: 32px;
    padding: 10px 10px 10px 12px;
    display: flex;
    max-width: 200px;
    align-items: center;
    justify-content: space-between;
    & > span {
      @extend .base-typography;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: $color_nightsky;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 70px;
    }
    button {
      @extend .button-reset;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      flex: 0 0 16px;
      background-color: $color_azure;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 8px;
        height: 8px;
        display: block;
      }
    }

    &.inverse {
      background-color: $color_nightsky;
      & > span {
        color: #fff;
      }
      button {
        background-color: #fff;
      }
    }
  }

  &.txt-md {
    .selected-item {
      height: 36px;
      & > span {
        font-size: 14px;
        height: 18px;
        margin-top: 2px;
      }
    }
  }
}

.select-multiple.thicker {
  font-weight: 600;
  span {
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .select-multiple.thicker {
    height: auto !important;
    min-height: 49px;
  }
  .select-multiple {
    .input {
      height: 44px;
      input[type="text"] {
        font-size: 12px;
        height: 44px;
      }
    }
    .options {
      .option-item {
        padding: 16px;
        font-size: 12px;
      }
    }
    .selected-options {
      display:flex;
      flex-direction: column;
    }
    .selected-item {
      padding: 10px 12px;
      & > span {
        font-size: 10px;
        font-family: $fontFamilySemiBold;
        order: 2;
      }
      & > button {
        order: 1;
        margin-left:0;
        margin-right:8px;
      }

      &.txt-md {
        & > span {
          font-size: 10px;
          font-family: $fontFamilySemiBold;
          font-weight: 500;
          height: 10px;
        }
      }
    }
  }
}
