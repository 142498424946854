.project-budget {
  button.remove {
    border:none;
    background:transparent;
    width: 40px;
    height: 40px;
    img {
      display:block;
      width:100%;
    }
  }  

  button.add-option {
    span {
      letter-spacing: 0.05em;
    }
  }
}