.menu {
  width: 18.22916666666667%;
  max-width: 350px;
  min-width: 280px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top:0;
  left:0;
  z-index: 15;
  min-height: 800px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  .nav-items {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .menu {
    display: none;
    width: 100vw;
    max-width: unset;
    min-width: unset;
    height: calc(100vh - 80px);
    min-height: auto;
    top: 80px;
    overflow-y: scroll;
    .logo {
      display: none;
    }
    &.active {
      display: flex;
    }
  }
}
