.footer {
  background-image: url('../imgs/footer-bg.png');
  background-repeat: no-repeat;
  background-size:cover;

  .border-sep {
    border-bottom-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: rgba(255,255,255, .1) !important;
  }

  a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    .border-sep {
      border-right-color:transparent;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: rgba(255,255,255, .1) !important;
    }
  }
}
