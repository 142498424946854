.main__deliverables-form {
  width: 100%;
  display:flex;
  .upload-field {
    width: 100%;
    .field-label {
      margin-left:50%;
    }
    .input-files {
      flex-direction: row-reverse;

      .drop-area-container {
        margin-left: auto;
        flex: 0 0 50%;
      }
      .uploaded-files {
        flex: 0 0 50%;
        padding-right:18px; 
        .file {
          .name {
            line-height: 140%;
          }
        }
      }
    }
  }
}