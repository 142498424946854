@import "../../../../assets/styles/resets.scss";

.generic-modal {
  display: block;
  transition: transform 0.3s ease-out;
  position: fixed;
  z-index: 1060;
  pointer-events: none;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  overflow-x: hidden;
  overflow-y: auto;

  &.active {
    visibility: visible;
    pointer-events: all;
  }

  .modal-box {
    position: relative;
    margin-top: 1.75rem auto;
    padding: 40px 43px;
    background-color: #fff;
    border-radius: 24px;
    width: 95%;

    & > .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      height: 60px;

      h3 {
        align-self: flex-end;
        width: 100%;
        flex: 1 1 auto;
        text-align: center;
        padding-left: 58px;
        padding-right: 16px;
      }

      .close {
        align-self: flex-start;
        flex: 0 0 42px;
        @extend .button-reset;
        float: right;
      }
    }

    .body {
      clear: both;
      padding: 10px 53px;
    }

    &.sm {
      max-width: 557px;

      button.btn {
        width: 100%;
      }
    }

    &.md {
      max-width: 858px;
    }

    &.lg {
      max-width: 954px;
    }

    &.xl {
      max-width: 1290px;
    }
  }

  .modal-centered {
    position: absolute;
    z-index: 102;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  &.thin-margins {
    .modal-box {
      padding: 10px;
      .body {
        padding: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .generic-modal {
    width: 100% !important;
    .modal-box {
      width: 95% !important;
      margin: 5% auto !important;
      padding: 5% !important;
      .body {
        padding: 0;
      }

      &.lg {
        max-width: 100% !important;
      }
    }
  }
}