@import '../../../assets/styles/variables.scss';

#jobs-page {
  .jobs-image {
    display: block;
    width: 100%;
    height: 100%;
    img {
      object-fit: contain;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }

  .title-opportunities {
    margin-bottom: 64px;
    margin-top: 120px;
  }

  .job-posting-item {
    width: 414px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .description {
      margin-top: 24px;
      width: 352px;
      justify-self: center;
    }
    a {
      margin-top:20px;
      color: $color_java;
      font-size:14px;
      font-family: $fontFamilyBold;
    }
  }
}

@media screen and (max-width: 1024px) {
  #jobs-page {
    h6 {
      font-size:10px;
    }
    h1 {
      font-size: 32px;
      line-height: 38.3px;
    }
    h5 {
      font-size: 14px;
    }
    .w-75 {
      width: 100% !important;
    }

    .title-opportunities {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 32px;
    }

    .job-posting-item {
      margin-bottom: 40px;
      h1.sm {
        font-size: 20px;;
      }
      .description {
        margin-top: 0px;
      }
    }
  }
}