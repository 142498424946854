@use "./variables" as var;

@import "./typography.scss";

input.reset {
  padding:0;
  margin:0;
  border:none;
}

.input-text-compact {
  clear: both;
  margin-top: 0;
  @extend .base-typography;
  border: 1px solid var.$color_input_border;
  border-radius: 8px;
  padding: 8px;
  color: var.$color_nightsky;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;

  &:focus-visible {
    outline: 0;
  }

  &:empty {
    background-color: #fff;
  }

  &::placeholder {
    background-color: #fff;
    opacity: 0.6;
    font-weight: 300;
  }
}

form {
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    b {
      @extend .bold-type;
      color: #17224D;
      font-size: 14px;
      line-height: 100%;
      font-weight: 500;
      margin-bottom: 12px;
      
    }
    & > .text {
      @extend .base-typography;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.06em;
      font-weight: 500;
      color: var.$color_form_label;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    & > .email {
      @extend .base-typography;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.06em;
      font-weight: 500;
      color: var.$color_form_label;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    & > .number {
      @extend .base-typography;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.06em;
      font-weight: 500;
      color: var.$color_form_label;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    & > .bold {
      @extend .bold-type;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: var.$color_nightsky;
      margin-bottom: 16px;
    }

    & > .thicker {
      @extend .base-typography;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      color: var.$color_nightsky;
      margin-bottom: 0px;
    }

    & > input,
    & > textarea,
    & > .input-checkbox,
    & > .input-radio,
    & > .input-radio-group {
      margin-top: 8px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  .input-text {
    clear: both;
    margin-top: 0;
    @extend .base-typography;
    border: 1px solid var.$color_input_border;
    border-radius: 8px;
    padding: 17px;
    color: var.$color_nightsky;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;

    &:focus-visible {
      outline: 0;
    }

    &:empty {
      background-color: #fff;
    }

    &::placeholder {
      background-color: #fff;
      opacity: 0.6;
      font-weight: 300;
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;

    &:first-child {
      margin-top: 0;
    }

    & > .push-right {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      justify-content: space-between;
      align-items: center;
    }
  }

  .form-control-group {
    background: #fcfcfc;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 32px 24px;
    height: 100%;
    
    .form-control {
      border: none !important;
      padding: 0;
      margin: 0;
      margin-bottom: 32px;
      background-color: transparent;
      .field-header {
        color: var.$color_nightsky;
        font-family: var.$fontFamilyBold;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .form-check {
      border: none;
      padding: 0;
      background-color: transparent;
    }
  }

  textarea {
    font-family: var.$fontFamilyRegular;
    color: var.$color_nightsky;
    font-size: 14px;
    line-height: 160%;
    font-weight: 300;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;

    &:empty {
      background-color: #fff;
    }

    &::placeholder {
      background-color: #fff;
      opacity: 0.6;
      font-weight: 300;
    }

    &:focus-visible {
      outline: 0;
    }

    &.textarea-3 {
      height: 10em;
    }
  }
    
  .input-group {
    .select-one + .input-money,
    .select-one + input[type="text"],
    .select-one + input[type="number"] {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 15px 18px;
      }
    }
    .input-money + .select-one,
    input[type="text"] + .select-one,
    input[type="number"] + .select-one {
      .input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .selected {
          font-size: 12px;
        }
      }
    }

    &.seamless {
      .select-one + .input-money,
      .select-one + input[type="text"],
      .select-one + input[type="number"] {
        input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-right: 0;
        }
      }
      .input-money + .select-one,
      input[type="text"] + .select-one,
      input[type="number"] + .select-one {
        .input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
          .selected {
            font-size: 12px;
          }
        }
      }
    }
  }

  .input-group.seamless:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), 
  .input-group.seamless:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right:0;
      width:100%;
    }
  }

  .form-control.inset {
    border: none;
    label {
      @extend .base-typography;
      color: var.$color_text;
      font-size: 10px;
      padding: 8px;
      position: relative;
      z-index: 2;
      top: 16px;
      left: 16px;
      flex: 1 1 auto;
      display: inline-block;
      background-color: #fff;
    }
    .select-multiple {
      border: none;
    }
  }
}

@media screen and (max-width: 768px) {
  form {

    label {

      &>.text {
        font-size: 10px;
      }

      textarea {
        margin-top: 0;
      }
    }

    .form-control.inset {
      label {
        top:unset;
        left:unset;
        padding-left:0;
        background-color: unset;
        text-transform: uppercase;
      }
    }

    .field-header {
      font-family: var.$fontFamilyMedium;
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .form-control-group {
      padding: 24px;
      .form-control {
        margin-bottom: 0;
        .field-header {
          font-family: var.$fontFamilyMedium;
          font-size: 10px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }
    }

    textarea {
      font-weight: 300;
      font-size: 12px;
      padding: 16px;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    .input-text {
      padding: 17px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      padding: 16px;

      &:focus-visible {
        outline: 0;
      }

      &:empty {
        background-color: #fff;
      }

      &::placeholder {
        background-color: #fff;
        opacity: 0.6;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
