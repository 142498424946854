.matching-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255,255,255,0.7);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {

  }
  h2 {
    
  }
}