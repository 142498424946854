.action-bar {
  .btn-primary {
    width: 136px;
  }
  .row.border-round {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 56px;
  }
}

@media screen and (max-width: 500px) {
  .action-bar {
    .btn-primary {
      padding: 20px 36px;
      font-size: 14px;
      width: 100%;
    }
    .row.border-round {
      border:none;
    }
  }
}