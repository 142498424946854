@import '../../../../../assets/styles/typography.scss';
@import '../../../../../assets/styles/variables.scss';

.match-dial {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
  background-color: $color_nightsky;
  .ring-wrapper {
    position: absolute;
    width: 74px;
    height: 74px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    svg.circle {
      display: block;
      width: 74px;
      height:74px;
      position: absolute;
      .progress-circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke-dasharray: 0 999;
        stroke: $color_java;
        stroke-width: 5px;
      }
    }
    svg.circle-dark {
      display: block;
      width: 74px;
      height:74px;
      .progress-circle {
        stroke: $color_java;
        opacity: .5;
        stroke-width: 5px;
      }
    }
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    .percent {
      font-weight: bold;
      font-size: 21px;
      line-height: 100%;
      text-align: center;
      color: #fff;
    }
    .label {
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: $color_java;
    }
  }

  &.light {
    background-color: transparent;
    .ring-wrapper {
      svg.circle {
        .progress-circle {
        }
      }
      svg.circle-dark {
        .progress-circle {
          stroke: $color_grey_dark;
          opacity: .1;
        }
      }
    }
    .text {
      .percent {
        color: $color_nightsky;
      }
      .label {
        color: $color_nightsky;
      }
    }
  }

  &.lg {
    width: 100px;
    height: 100px;
    .ring-wrapper {
      width: 84px;
      height: 84px;
      svg.circle {
        width: 84px;
        height: 84px;
        .progress-circle {
          stroke-width: 6px;
        }
      }
      svg.circle-dark {
        width: 84px;
        height: 84px;
        .progress-circle {
          stroke-width: 6px;
        }
      }
    }
    .text {
      .percent {
        font-size: 24px;
        margin-bottom:4px;
      }
      .label {
        font-size: 11px;
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width:768px) {
  .match-dial {
    width: 74px;
    height: 74px;
    .ring-wrapper {
      transform: translate(-50%, -50%) scale(0.85);
      svg.circle {
      }
      svg.circle-dark {
        .progress-circle {
          stroke-width: 5px;
        }
      }
    }
    .text {
      .percent {
        font-size: 18px;
      }
      .label {
        font-size: 8px;
      }
    }

    &.lg {
      width: 60px;
      height: 60px;
      .ring-wrapper {
        transform: translate(-50%,-50%) scale(0.8);
      }
      .text {
        .percent {
          font-size: 17px;
        }
        .label {
          font-size: 8px;
        }
      }
    }
  }
}