@use '../../../../../assets/styles/variables' as *;
@import '../../../../../assets/styles/typography.scss';

.news {
  display:flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.article-short {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  height: 154px;
  margin-bottom: 12px;
  margin-top: 12px;
  .inner {
    display: flex;
    flex-direction: row;
    padding: 24px;
    padding-right: 0px;
    
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .image {
    flex: 0 0 106px;
    width: 106px;
    height: 106px;
    overflow: hidden;
    img {
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .body {
    
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 100%;
    width: 0;
    .title {
    
      flex-shrink: 1;
      flex-grow: 1;
      max-width: 100%;
      h5 {
        display: inline-block;
        max-width: 100%;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .content {
      @extend .base-typography;
      margin-top:.2em;
      margin-left: 0 !important;
      font-size: 14px;
      line-height: 19.6px;
      font-weight: 400;
      color: $color_text;
      max-height: 39.2px;
      overflow: hidden;
    }
  }
  .more {
    flex: 0 0 44px;
    height: 100%;
    opacity: 0.2;
    cursor: pointer;
    background-color: $color_grey;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {

      }
    }
  }
}

@media screen and (max-width:768px) {
  .news {
    .article-short {
      height: auto !important;
      .inner {
        flex-direction: column;
        padding:0;
      }
      .image {
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .body {
        flex-shrink: 0;
        flex-grow: 1;
        width: 100%;
        flex-direction: column;
        .title {
          flex-grow: 1;
          height:auto;
          h5 {
            font-size: 14px;
            line-height: 130%;
            white-space: normal;
          }
        }
        .content {
          font-size: 12px;
          line-height: 140%;
          max-height: auto;
        }
      }
      .more {
        display: none;
      }
    }
  }
}