.corporate-match-results {
  .match-tech-solution-card-image-overlay {
    width: 100%;
    height: 100%;
    position: relative;
    .match-percent {
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translateY(-50%,);
    }
    .favourite {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  
  .match-tech-solutions-inner {
    gap: 32px;

    .card-wrapper.match-tech-solution-card {
      height: 360px;
    }
  }
}

@media screen and (max-width:768px) {
  .corporate-match-results {
    
  .match-tech-solution-card-image-overlay {
    .match-percent {
      left: 24px;
    }
    .favourite {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

    .match-tech-solutions-inner {
      margin-left: 0;
      margin-right: 0;
      .card-wrapper.match-tech-solution-card {
        margin-left:0;
        margin-right: 0;
        .card {
          .card-body {
            .card-body-content {
              h5 {
                line-height: 140%;
              }
            }
          }
          .card-footer {
            padding:0;
            .tech-solution-match-result-card-footer { 
              padding: 5px 24px;
              .info {
                align-items: center;
                justify-content: space-between;
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}