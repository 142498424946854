@media screen and (max-width:768px) {
  .tile.settings-form {
    .inner {
      .body {
        .body-inner {
          .row {
            .col {
              &.actions {
                button {
                  width: 100%;
                  margin:0 !important;
                }
              }
            }  
          }
        }
      }
    }
  } 
}

